import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import wordConverter from "num-words";
import {
  Add,
  Cancel,
  Close,
  Dashboard,
  Receipt,
  Save,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import Button from "@jumbo/components/JumboLoadingButton";
import { CASH_SELL, HOME } from "@jumbo/constants/routes";
import Div from "@jumbo/shared/Div";
import { getPartiList } from "app/redux/slice/partiSlice";
import moment from "moment";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { getStoreList } from "app/redux/slice/storeSlice";
import { getCustomerList } from "app/redux/slice/customerSlice";
import { getCategoryList } from "app/redux/slice/categorySlice";
import { getProductList } from "app/redux/slice/productSlice";
import {
  createCashSell,
  deleteCashSellItem,
  editCashSell,
  updateCashSell,
} from "app/redux/slice/cashSellSlice";

const useStyles = makeStyles({
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  mainInfoContainer: {
    display: "flex",
    minHeight: "170px",
    justifyContent: "center",
    flexDirection: "column",
  },
  headingTitleContainer: {
    display: "flex",
    flexGrow: "wrap",
  },
  headingTitle: {
    minWidth: "80px",
  },
  headingContent: {
    fontWeight: "500",
  },
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid",
      borderBottom: "1px solid",
    },
  },
  particular: {
    minWidth: "140px",
  },
  gst: {
    minWidth: "100px",
  },
  product: {
    minWidth: "200px",
  },
  amount: {
    minWidth: "100px",
    // verticalAlign: "top",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "10px 0px",
  },
  margin: {
    "& > :first-child": {
      marginTop: "10px",
    },
    marginBottom: "10px",
  },
  alignTop: {
    verticalAlign: "top",
  },
  loadingButtonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchema = yup.object({
  customer_id: yup.string().required("Please select customer."),
  category_id: yup.string().required("Please select category."),
  cash_sell_number: yup.string().required("Please enter slip number."),
  date: yup.string().required("Please select date."),
  due_date: yup.string().required("Please select due date."),
  Jewelry: yup.array().when("category_id", {
    is: "1",
    then: yup.array().of(
      yup.object().shape({
        product_id: yup.string().required("Please select product"),
        carat: yup.string().required("Please select carat"),
        weight: yup
          .number()
          .required("Please enter weight")
          .min(0, "weight greater than or equal to 1"),
        quantity: yup
          .number()
          .required("Please enter quantity")
          .min(1, "quantity greater than or equal to 1"),
        price: yup
          .number()
          .required("Please enter price")
          .min(1, "price greater than or equal to 1"),
      })
    ),
  }),
  Diamond: yup.array().when("category_id", {
    is: "2",
    then: yup.array().of(
      yup.object().shape({
        product_id: yup.string().required("Please select product"),
        weight: yup
          .number()
          .required("Please enter carat")
          .min(0, "carat greater than or equal to 1"),
        quantity: yup
          .number()
          .required("Please enter quantity")
          .min(1, "quantity greater than or equal to 1"),
        price: yup
          .number()
          .required("Please enter price")
          .min(1, "price greater than or equal to 1"),
      })
    ),
  }),
});

const AddAndUpdateCashSell = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const categoryData = useSelector((state) => state?.category);
  const customerData = useSelector((state) => state?.customer);
  const productData = useSelector((state) => state?.product);
  const [category, setCategory] = useState(null);
  const [customer, setCustomer] = useState(null);

  let sum = 0;

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: CASH_SELL,
      icon: <Receipt />,
      label: "Cash Sell",
    },
    {
      label: id ? "Update Cash Sell" : "Add Cash Sell",
    },
  ];
  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const formik = useFormik({
    initialValues: {
      store_id: localStorage.getItem("store_id"),
      date: moment(new Date()).format("YYYY-MM-DD"),
      due_date: "",
      cash_sell_number: "",
      Jewelry: id
        ? []
        : [
            {
              id: null,
              product_id: "",
              carat: "",
              weight: 0,
              quantity: 0,
              price: 0,
              total: 0,
              disable: false,
            },
          ],
      Diamond: id
        ? []
        : [
            {
              id: null,
              product_id: "",
              weight: 0,
              quantity: 0,
              price: 0,
              total: 0,
              disable: false,
            },
          ],
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      const productsArr = [];
      if (values.category_id === 1) {
        values.Jewelry?.forEach((val) => {
          productsArr.push({
            product_id: val.product_id,
            carat: val.carat,
            weight: val.weight,
            quantity: val.quantity,
            price: val.price,
            total: val.total,
            ...(val?.id && { id: val.id }),
          });
        });
      } else {
        values.Diamond?.forEach((val) => {
          productsArr.push({
            product_id: val.product_id,
            weight: val.weight,
            quantity: val.quantity,
            price: val.price,
            total: val.total,
            ...(val?.id && { id: val.id }),
          });
        });
      }

      setSubmitting(true);
      const newCashSellData = {
        customer_id: values.customer_id,
        store_id: localStorage.getItem("store_id"),
        category_id: values.category_id,
        cash_sell_number: values.cash_sell_number?.trim(),
        cash_sell_date: values.date,
        due_date: values?.due_date,
        cash_sell_amount: Math.round((sum ?? 0) * 100) / 100,
        items: productsArr,
      };
      // console.log(newInvoiceData);
      // return;
      const response = await dispatch(
        id
          ? updateCashSell({ id, data: newCashSellData })
          : createCashSell(newCashSellData)
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(CASH_SELL);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });

  const { values, setFieldValue, errors } = formik;
  const serviceLengthJewelry = values?.Jewelry?.length;
  const serviceLengthDiamond = values?.Diamond?.length;

  const handleChange = (index, field, value, max = 0) => {
    let updatedFields = values.Jewelry;

    if (field === "weight") {
      if (value > max) value = max;
      const total1 =
        (updatedFields[index].quantity ?? 1) *
        (updatedFields[index].price ?? 1) *
        value;
      updatedFields[index].total = Math.round(total1 * 100) / 100;
    }
    if (field === "quantity") {
      if (value > max) value = max;
      const total2 =
        (updatedFields[index].weight ?? 1) *
        (updatedFields[index].price ?? 1) *
        value;
      updatedFields[index].total = Math.round(total2 * 100) / 100;
    }
    if (field === "price") {
      const total3 =
        (updatedFields[index].quantity ?? 1) *
        (updatedFields[index].weight ?? 1) *
        value;
      updatedFields[index].total = Math.round(total3 * 100) / 100;
    }
    if (field === "total") {
      const price =
        value /
        ((updatedFields[index].quantity ?? 1) *
          (updatedFields[index].weight ?? 1));
      updatedFields[index].price = Math.round(price * 100) / 100;
    }
    updatedFields[index][field] = value;
    setFieldValue("Jewelry", updatedFields);
  };

  const handleAddMore = () => {
    formik.setValues({
      ...formik.values,
      Jewelry: [
        ...formik.values.Jewelry,
        {
          id: null,
          product_id: "",
          carat: "",
          weight: 0,
          quantity: 0,
          price: 0,
          total: 0,
          disable: false,
        },
      ],
    });
  };

  const handleDeleteField = async (index, id) => {
    if (id) {
      await dispatch(deleteCashSellItem(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Jewelry;
            updatedFields.splice(index, 1);
            setFieldValue("Jewelry", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Jewelry;
      updatedFields.splice(index, 1);
      setFieldValue("Jewelry", updatedFields);
    }
  };

  const handleChangeDiamond = (index, field, value, max = 0) => {
    let updatedFields = values.Diamond;

    if (field === "weight") {
      if (value > max) value = max;
      const total1 = (updatedFields[index].price ?? 1) * value;
      updatedFields[index].total = Math.round(total1 * 100) / 100;
    }
    if (field === "price") {
      const total2 = (updatedFields[index].weight ?? 1) * value;
      updatedFields[index].total = Math.round(total2 * 100) / 100;
    }
    if (field === "quantity") {
      if (value > max) value = max;
    }
    if (field === "total") {
      const price = value / (updatedFields[index].weight ?? 1);
      updatedFields[index].price = Math.round(price * 100) / 100;
    }
    updatedFields[index][field] = value;
    // }
    setFieldValue("Diamond", updatedFields);
  };

  const handleAddMoreDiamond = () => {
    formik.setValues({
      ...formik.values,
      Diamond: [
        ...formik.values.Diamond,
        {
          id: null,
          product_id: "",
          weight: 0,
          quantity: 0,
          price: 0,
          total: 0,
          disable: false,
        },
      ],
    });
  };

  const handleDeleteFieldDiamond = async (index, id) => {
    if (id) {
      await dispatch(deleteCashSellItem(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Diamond;
            updatedFields.splice(index, 1);
            setFieldValue("Diamond", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Diamond;
      updatedFields.splice(index, 1);
      setFieldValue("Diamond", updatedFields);
    }
  };

  useEffect(() => {
    dispatch(getStoreList());
    dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
    dispatch(getCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(editCashSell(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            const cashSellDetail = response?.data?.data?.cash_sell_detail;
            setCustomer(cashSellDetail?.customer);
            setCategory(cashSellDetail?.category);
            dispatch(
              getProductList({
                category_id: cashSellDetail?.category_id,
                store_id: localStorage.getItem("store_id"),
              })
            );
            setFieldValue(
              "date",
              moment(cashSellDetail?.cash_sell_date).format("YYYY-MM-DD")
            );
            if (cashSellDetail?.due_date) {
              setFieldValue(
                "due_date",
                moment(cashSellDetail?.due_date).format("YYYY-MM-DD")
              );
            }
            setFieldValue("cash_sell_number", cashSellDetail?.cash_sell_number);
            setFieldValue("customer_id", cashSellDetail?.customer_id);
            setFieldValue("category_id", cashSellDetail?.category_id);
            if (cashSellDetail?.category_id === 1) {
              setFieldValue("Jewelry", cashSellDetail?.cash_sell_items);
            }
            if (cashSellDetail?.category_id === 2) {
              setFieldValue("Diamond", cashSellDetail?.cash_sell_items);
            }
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    }
  }, [dispatch, enqueueSnackbar, id, setFieldValue]);

  useEffect(() => {
    dispatch(getCustomerList({ store_id: localStorage.getItem("store_id") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { handleSubmit } = formik;
  return (
    <PageContainer
      heading={id ? "Update Cash Sell" : "Add Cash Sell"}
      breadcrumb={breadcrumbData}
    >
      <Card>
        <CardContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container border={1}>
                <Grid item sm={2} xs={12} borderRight={{ xs: 0, sm: 1 }}>
                  <Grid>
                    <Grid px={1} pt={1} mb={1}>
                      <JumboAutoComplete
                        options={categoryData?.list ?? []}
                        label="Category*"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="category_id"
                        onChange={(evt, value) => {
                          setFieldValue("category_id", value?.id);
                          setCategory(value);
                          if (value?.id)
                            dispatch(
                              getProductList({
                                category_id: value?.id,
                                store_id: localStorage.getItem("store_id"),
                              })
                            );
                        }}
                        disabled={id}
                        value={category}
                        error={formik?.errors?.category_id}
                        helperText={formik?.errors?.category_id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={12} borderRight={{ xs: 0, sm: 1 }}>
                  <Grid>
                    <Grid px={1} pt={1} mb={1}>
                      <JumboAutoComplete
                        options={customerData.list}
                        label="Customer*"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="customer_id"
                        onChange={(evt, value) => {
                          setFieldValue("customer_id", value?.id);
                          setCustomer(value);
                        }}
                        value={customer}
                        error={formik?.errors?.customer_id}
                        helperText={formik?.errors?.customer_id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <Grid container>
                    <Grid
                      item
                      sm={4}
                      xs={12}
                      borderRight={{ xs: 0, sm: 1 }}
                      borderBottom={1}
                      p={1}
                    >
                      <TextField
                        label={"Slip No."}
                        name="cash_sell_number"
                        fullWidth
                        size="small"
                        value={values?.cash_sell_number}
                        onChange={(evt) =>
                          setFieldValue("cash_sell_number", evt.target.value)
                        }
                        disabled={id}
                        error={errors?.cash_sell_number}
                        helperText={errors?.cash_sell_number}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      xs={12}
                      borderBottom={1}
                      p={1}
                      borderRight={{ xs: 0, sm: 1 }}
                    >
                      <TextField
                        label={"Date"}
                        name="date"
                        fullWidth
                        size="small"
                        type="date"
                        onChange={(evt) =>
                          setFieldValue("date", evt.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        value={values?.date}
                        error={errors?.date}
                        helperText={errors?.date}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12} borderBottom={1} p={1}>
                      <TextField
                        label={"Due Date"}
                        name="due_date"
                        fullWidth
                        size="small"
                        type="date"
                        onChange={(evt) =>
                          setFieldValue("due_date", evt.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        value={values?.due_date}
                        error={errors?.due_date}
                        helperText={errors?.due_date}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} borderRight={1} borderBottom={1}>
                <TableContainer component={Paper}>
                  {values?.category_id === 1 && (
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                      className={classes?.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell className={classes?.product}>
                            Product
                          </TableCell>
                          <TableCell>Carat</TableCell>
                          <TableCell>Weight Per Qty (gm)</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Price per gm</TableCell>
                          <TableCell className={classes?.amount}>
                            Total
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {serviceLengthJewelry &&
                          values?.Jewelry.map((item, index) => {
                            sum += item?.total ? +item?.total : 0;
                            return (
                              <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className={classes?.product}>
                                  <Div className={classes?.margin} key={index}>
                                    {item.product ? (
                                      <TextField
                                        value={item?.product?.name}
                                        size="small"
                                      />
                                    ) : (
                                      <JumboAutoComplete
                                        options={productData?.list ?? []}
                                        label="Product*"
                                        size="small"
                                        getOptionLabel={(option) => option.name}
                                        name="product_id"
                                        onChange={(evt, value) => {
                                          handleChange(
                                            index,
                                            "product_id",
                                            value?.id
                                          );
                                          handleChange(
                                            index,
                                            "productData",
                                            value
                                          );
                                          handleChange(
                                            index,
                                            "price",
                                            +value?.price ?? 0
                                          );
                                          handleChange(
                                            index,
                                            "carat",
                                            value?.carat
                                          );
                                          handleChange(
                                            index,
                                            "weight",
                                            +value?.remaining_weight,
                                            +value?.remaining_weight
                                          );
                                          handleChange(
                                            index,
                                            "quantity",
                                            +value?.remaining_quantity,
                                            +value?.remaining_quantity
                                          );
                                          handleChange(
                                            index,
                                            "disable",
                                            +value?.remaining_quantity === 1
                                          );
                                        }}
                                        error={
                                          formik?.errors?.Jewelry?.length &&
                                          formik?.errors?.Jewelry[index]
                                            ?.product_id
                                        }
                                        helperText={
                                          formik?.errors?.Jewelry?.length &&
                                          formik?.errors?.Jewelry[index]
                                            ?.product_id
                                        }
                                      />
                                    )}
                                  </Div>
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Carat*"}
                                    name={`carat[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.carat}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "carat",
                                        +evt.target.value
                                      )
                                    }
                                    disabled
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.carat
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.carat
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Weight*"}
                                    name={`weight[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.weight}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "weight",
                                        +evt.target.value,
                                        +item?.productData?.remaining_weight ??
                                          0
                                      )
                                    }
                                    disabled={(id && item?.id) || item.disable}
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_weight ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.weight
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.weight
                                    }
                                  />
                                </TableCell>

                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Quantity*"}
                                    name={`quantity[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.quantity}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "quantity",
                                        +evt.target.value,
                                        +item?.productData
                                          ?.remaining_quantity ?? 0
                                      )
                                    }
                                    disabled={(id && item?.id) || item.disable}
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_quantity ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.quantity
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.quantity
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Price per gm*"}
                                    name={`price[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.price}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "price",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.price
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.price
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Total*"}
                                    name={`total[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.total}
                                    type="number"
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "total",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.total
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.total
                                    }
                                    // disabled
                                  />
                                </TableCell>

                                <TableCell>
                                  {(index !== 0 || id) && (
                                    <IconButton
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() =>
                                        handleDeleteField(index, item?.id)
                                      }
                                    >
                                      <Cancel />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {serviceLengthJewelry && (
                          <TableRow>
                            <TableCell />
                            <TableCell
                              colSpan={5}
                              className={classes?.headingContent}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              className={classes?.headingContent}
                            >
                              {Math.round((sum ?? 0) * 100) / 100}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell colSpan={9}>
                            <Grid className={classes?.buttonContainer}>
                              <Button
                                type="button"
                                buttonLabel={"Add"}
                                sx={{ marginRight: "10px" }}
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={handleAddMore}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {serviceLengthJewelry && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Grid container>
                                <Grid item sm={11}>
                                  <Typography variant="body1">
                                    Amount Chargeable (in words)
                                  </Typography>
                                </Grid>
                                <Grid item sm={1}>
                                  <Typography variant="body1">
                                    E. & O.E
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography variant="subtitle2">
                                {capitalizeFirstLetter(
                                  wordConverter(Math.round(sum ?? 0))
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                  {values?.category_id === 2 && (
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                      className={classes?.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell className={classes?.particular}>
                            Product
                          </TableCell>
                          <TableCell>Carat</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Price per Carat</TableCell>
                          <TableCell className={classes?.amount}>
                            Total
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {serviceLengthDiamond &&
                          values?.Diamond.map((item, index) => {
                            sum += item?.total ? +item?.total : 0;
                            return (
                              <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className={classes?.product}>
                                  <Div className={classes?.margin} key={index}>
                                    {item.product ? (
                                      <TextField
                                        value={item?.product?.name}
                                        size="small"
                                      />
                                    ) : (
                                      <JumboAutoComplete
                                        options={productData?.list ?? []}
                                        label="Product*"
                                        size="small"
                                        getOptionLabel={(option) => option.name}
                                        name="product_id"
                                        onChange={(evt, value) => {
                                          handleChangeDiamond(
                                            index,
                                            "product_id",
                                            value?.id
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "productData",
                                            value
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "price",
                                            +value?.price
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "weight",
                                            +value?.remaining_weight,
                                            +value?.remaining_weight
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "quantity",
                                            +value?.remaining_quantity,
                                            +value?.remaining_quantity
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "disable",
                                            +value?.remaining_quantity === 1
                                          );
                                        }}
                                        error={
                                          formik?.errors?.Diamond?.length &&
                                          formik?.errors?.Diamond[index]
                                            ?.product_id
                                        }
                                        helperText={
                                          formik?.errors?.Diamond?.length &&
                                          formik?.errors?.Diamond[index]
                                            ?.product_id
                                        }
                                      />
                                    )}
                                  </Div>
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Carat*"}
                                    name={`weight[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.weight}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "weight",
                                        +evt.target.value,
                                        +item?.productData?.remaining_weight ??
                                          0
                                      )
                                    }
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_weight ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    disabled={(id && item?.id) || item?.disable}
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.weight
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.weight
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Quantity*"}
                                    name={`quantity[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.quantity}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "quantity",
                                        +evt.target.value,
                                        +item?.productData
                                          ?.remaining_quantity ?? 0
                                      )
                                    }
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_quantity ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    disabled={(id && item?.id) || item?.disable}
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.quantity
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.quantity
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Price per carat*"}
                                    name={`price[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.price}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "price",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.price
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.price
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Total*"}
                                    name={`total[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.total}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "total",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.total
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.total
                                    }
                                    // disabled
                                  />
                                </TableCell>

                                <TableCell>
                                  {(index !== 0 || id) && (
                                    <IconButton
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() =>
                                        handleDeleteFieldDiamond(
                                          index,
                                          item?.id
                                        )
                                      }
                                    >
                                      <Cancel />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {serviceLengthDiamond && (
                          <TableRow>
                            <TableCell />
                            <TableCell
                              colSpan={4}
                              className={classes?.headingContent}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              className={classes?.headingContent}
                            >
                              {Math.round((sum ?? 0) * 100) / 100}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell colSpan={9}>
                            <Grid className={classes?.buttonContainer}>
                              <Button
                                type="button"
                                buttonLabel={"Add"}
                                sx={{ marginRight: "10px" }}
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={handleAddMoreDiamond}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {serviceLengthDiamond && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Grid container>
                                <Grid item sm={11}>
                                  <Typography variant="body1">
                                    Amount Chargeable (in words)
                                  </Typography>
                                </Grid>
                                <Grid item sm={1}>
                                  <Typography variant="body1">
                                    E. & O.E
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography variant="subtitle2">
                                {capitalizeFirstLetter(
                                  wordConverter(Math.round(sum ?? 0))
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Grid>
              <br></br>
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  className={classes?.loadingButtonContainer}
                >
                  <LoadingButton
                    type="submit"
                    buttonLabel={id ? "Update" : "Save"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(CASH_SELL)}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AddAndUpdateCashSell;
