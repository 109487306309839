import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dashboard, Save, Close, Edit } from "@mui/icons-material";
import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import TextField from "@jumbo/components/JumboFormik/JumboTextField";
import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import { HOME, BANK_TRANSFER } from "@jumbo/constants/routes";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import JumboSelectBox from "@jumbo/components/JumboSelectBox";
import moment from "moment";
import {
  editBankTransaction,
  updateBankTransaction,
} from "app/redux/slice/bankTransactionSlice";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchema = yup.object({
  name: yup.string("").required("Please enter name."),
  transaction_type: yup.string("").required("Please enter transaction_type."),
  remark: yup.string("").required("Please enter remark."),
  amount: yup.number("").required("Please enter amount.").min(1),
  transaction_date: yup.date().required("Please select transaction date."),
});

const typeData = [
  { id: "credit", name: "Credit" },
  { id: "debit", name: "Debit" },
];

const AddAndUpdateBankTransfer = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: BANK_TRANSFER,
      icon: <AccountBalanceWalletIcon />,
      label: "Bank Transaction",
    },
    {
      icon: <Edit />,
      label: "Update",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      transaction_type: "",
      transaction_date: "",
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      const newBankTransactionData = {
        party_name: values.name?.trim(),
        transaction_type: values.transaction_type,
        transaction_date: values.transaction_date,
        amount: values.amount,
        remark: values.remark,
      };
      const response = await dispatch(
        updateBankTransaction({ id, data: newBankTransactionData })
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(BANK_TRANSFER);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(editBankTransaction(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            const bankTransactionDetail =
              response?.data?.data?.existing_expense;
            setFieldValue("name", bankTransactionDetail?.party_name);
            setFieldValue(
              "transaction_type",
              bankTransactionDetail?.transaction_type
            );
            setFieldValue(
              "transaction_date",
              moment(bankTransactionDetail?.transaction_date).format(
                "YYYY-MM-DD"
              )
            );
            setFieldValue("amount", bankTransactionDetail?.amount);
            setFieldValue("remark", bankTransactionDetail?.remark);
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;
  return (
    <>
      <PageContainer
        heading={"Update Bank Transaction"}
        breadcrumb={breadcrumbData}
      >
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField label={"Name"} name="name" fullWidth required />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="date"
                      label={"Date"}
                      name="transaction_date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required
                      value={values?.transaction_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <JumboSelectBox
                      fullWidth
                      name="type"
                      label="Payment Type*"
                      data={typeData}
                      size="large"
                      labelKey="name"
                      valueKey="id"
                      value={values?.transaction_type}
                      onChange={(evt) =>
                        setFieldValue("transaction_type", evt.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={"Amount*"}
                      name="amount"
                      type="number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField label={"Remark*"} name="remark" fullWidth />
                  </Grid>
                </Grid>

                <Grid item sm={12} className={classes?.buttonContainer}>
                  <LoadingButton
                    buttonLabel={"Update"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                    loading={isSubmitting || mutation.isLoading}
                    type="submit"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(BANK_TRANSFER)}
                  />
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default AddAndUpdateBankTransfer;
