import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import wordConverter from "num-words";
import {
  Add,
  Cancel,
  Close,
  Dashboard,
  ReceiptLong,
  Save,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import Button from "@jumbo/components/JumboLoadingButton";
import { BILL, HOME } from "@jumbo/constants/routes";
import Div from "@jumbo/shared/Div";
import { getPartiList } from "app/redux/slice/partiSlice";
import {
  createBill,
  deleteBillItem,
  deleteBillTax,
  editBill,
  getBillUniqDescriptionList,
  updateBill,
} from "app/redux/slice/billSlice";
import moment from "moment";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { getCategoryList } from "app/redux/slice/categorySlice";
// import { getProductList } from "app/redux/slice/productSlice";
import { getLastInvoiceAndBillNo } from "app/redux/slice/invoiceSlice";
import JumboSelectBox from "@jumbo/components/JumboSelectBox";
import { getStoreTaxList } from "app/redux/slice/storeSlice";
const filter = createFilterOptions();

const useStyles = makeStyles({
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  mainInfoContainer: {
    display: "flex",
    minHeight: "170px",
    justifyContent: "center",
    flexDirection: "column",
  },
  headingTitleContainer: {
    display: "flex",
    flexGrow: "wrap",
  },
  headingTitle: {
    minWidth: "80px",
  },
  headingContent: {
    fontWeight: "500",
  },
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid",
      borderBottom: "1px solid",
    },
  },
  particular: {
    minWidth: "140px",
  },
  gst: {
    minWidth: "100px",
  },
  product: {
    minWidth: "250px",
  },
  amount: {
    minWidth: "100px",
    // verticalAlign: "top",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "10px 0px",
  },
  margin: {
    "& > :first-child": {
      marginTop: "10px",
    },
    marginBottom: "10px",
  },
  alignTop: {
    verticalAlign: "top",
  },
  loadingButtonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchema = yup.object({
  vendor_id: yup.string().required("Please select vendor."),
  category_id: yup.string().required("Please select category."),
  bill_number: yup.string().required("Please enter Bill number."),
  bill_date: yup.string().required("Please select Bill date."),
  due_date: yup.string().required("Please select due date."),
  Jewelry: yup.array().when("category_id", {
    is: "1",
    then: yup.array().of(
      yup.object().shape({
        // product_id: yup.string().required("Please select product"),
        // product_id: yup.string().when("item", {
        //   is: (val) => !val, // product_id is required only when item is not provided
        //   then: yup.string().required("Please select product"),
        //   otherwise: yup.string().nullable(),
        // }),
        // item: yup.string().when("product_id", {
        //   is: (val) => !val, // item is required only when product_id is not provided
        //   then: yup.string().required("Please provide item"),
        //   otherwise: yup.string().nullable(),
        // }),
        carat: yup.string().required("Please select carat"),
        hsn_sac: yup
          .number()
          .required("Please enter hsn/sac")
          .min(1, "hsn/sac greater than or equal to 1"),
        weight: yup
          .number()
          .required("Please enter weight")
          .min(0, "weight greater than or equal to 1"),
        quantity: yup
          .number()
          .required("Please enter quantity")
          .min(1, "quantity greater than or equal to 1"),
        price: yup
          .number()
          .required("Please enter price")
          .min(1, "price greater than or equal to 1"),
        bill_taxes: yup.array().of(
          yup.object().shape({
            tax_name: yup
              .string()
              .nullable()
              .required("Please select bill_taxes"),
            tax_percentage: yup
              .number()
              .required("Please enter tax_percentage")
              .min(0, "tax_percentage greater than or equal to 1"),
          })
        ),
      })
    ),
  }),
  Diamond: yup.array().when("category_id", {
    is: "2",
    then: yup.array().of(
      yup.object().shape({
        // product_id: yup.string().required("Please select product"),
        hsn_sac: yup
          .number()
          .required("Please enter hsn/sac")
          .min(1, "hsn/sac greater than or equal to 1"),
        // weight: yup
        //   .number()
        //   .required("Please enter carat")
        //   .min(0, "carat greater than or equal to 1"),
        quantity: yup
          .number()
          .required("Please enter quantity")
          .min(1, "quantity greater than or equal to 1"),
        price: yup
          .number()
          .required("Please enter price")
          .min(1, "price greater than or equal to 1"),
        bill_taxes: yup.array().of(
          yup.object().shape({
            tax_name: yup
              .string()
              .nullable()
              .required("Please select bill_taxes"),
            tax_percentage: yup
              .number()
              .required("Please enter tax_percentage")
              .min(0, "tax_percentage greater than or equal to 1"),
          })
        ),
      })
    ),
  }),
});

const AddAndUpdateBill = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const categoryData = useSelector((state) => state?.category);
  const supplierData = useSelector((state) => state?.supplier);
  const taxData = useSelector((state) => state?.store);
  const billDescriptionData = useSelector((state) => state?.bill);
  const BillData = useSelector((state) => state?.invoice);

  const [category, setCategory] = useState(null);
  const [vendor, setVendor] = useState(null);

  let sum = 0;

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: BILL,
      icon: <ReceiptLong />,
      label: "Bill",
    },
    {
      label: id ? "Update Bill" : "Add Bill",
    },
  ];

  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const formik = useFormik({
    initialValues: {
      store_id: localStorage.getItem("store_id"),
      bill_date: moment(new Date()).format("YYYY-MM-DD"),
      due_date: "",
      bill_number: "",
      Jewelry: id
        ? []
        : [
            {
              id: null,
              product_id: "",
              item: null,
              hsn_sac: 0,
              gst_percentage: 3,
              carat: "",
              weight: 0,
              quantity: 0,
              price: 0,
              total: 0,
              bill_taxes: [],
            },
          ],
      Diamond: id
        ? []
        : [
            {
              id: null,
              product_id: "",
              item: null,
              hsn_sac: 0,
              gst_percentage: 1.5,
              weight: 0,
              quantity: 0,
              price: 0,
              total: 0,
              bill_taxes: [],
            },
          ],
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      const productsArr = [];
      if (values.category_id === 1) {
        values.Jewelry?.forEach((val) => {
          const productTaxArr = [];
          val.bill_taxes.forEach((taxVal) => {
            productTaxArr.push({
              tax_name: taxVal.tax_name,
              tax_percentage: taxVal.tax_percentage,
              tax_amount: taxVal.tax_amount,
              item_amount: val.total_without_tax ?? taxVal.item_amount,
              ...(taxVal?.id && { id: taxVal.id }),
            });
          });
          productsArr.push({
            hsn_sac: val.hsn_sac,
            gst_percentage: 3,
            carat: val.carat,
            weight: val.weight,
            quantity: val.quantity,
            price: val.price,
            total: val.total,
            item: val?.item,
            tax: productTaxArr,
            total_without_tax: val.total_without_tax,
            ...(val?.id && { id: val.id }),
          });
        });
      } else {
        values.Diamond?.forEach((val) => {
          const productTaxArr = [];
          val.bill_taxes.forEach((taxVal) => {
            productTaxArr.push({
              tax_name: taxVal.tax_name,
              tax_percentage: taxVal.tax_percentage,
              tax_amount: taxVal.tax_amount,
              item_amount: val.total_without_tax ?? taxVal.item_amount,
              ...(taxVal?.id && { id: taxVal.id }),
            });
          });
          productsArr.push({
            hsn_sac: val.hsn_sac,
            gst_percentage: 1.5,
            quantity: val.quantity,
            price: val.price,
            total: val.total,
            item: val?.item,
            total_without_tax: val.total_without_tax,
            tax: productTaxArr,
            ...(val?.id && { id: val.id }),
          });
        });
      }

      setSubmitting(true);
      const newBillData = {
        supplier_id: values.vendor_id,
        store_id: localStorage.getItem("store_id"),
        category_id: values.category_id,
        bill_number: values.bill_number?.trim(),
        bill_date: values.bill_date,
        due_date: values?.due_date,
        bill_amount: Math.round((sum ?? 0) * 100) / 100,
        items: productsArr,
      };
      // console.log(newBillData);
      // return;
      const response = await dispatch(
        id ? updateBill({ id, data: newBillData }) : createBill(newBillData)
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(BILL);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });

  const { values, setFieldValue, errors } = formik;

  const serviceLengthJewelry = values?.Jewelry?.length;
  const serviceLengthDiamond = values?.Diamond?.length;

  const handleChange = (index, field, value) => {
    let updatedFields = values.Jewelry;

    if (field === "weight") {
      // const total1 =
      //   (updatedFields[index].quantity ?? 1) *
      //   (updatedFields[index].price ?? 1) *
      //   value;
      // updatedFields[index].total =
      //   Math.round((total1 + total1 * 0.03) * 100) / 100;
      const total =
        (updatedFields[index].price ?? 1) *
        (updatedFields[index].quantity ?? 1) *
        value;
      let finalTotal = total;
      updatedFields[index].total_without_tax = total;
      updatedFields[index].bill_taxes.forEach((val, i) => {
        finalTotal += Math.round((total * val.tax_percentage) / 100);
        updatedFields[index].bill_taxes[i].tax_amount = Math.round(
          (total * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = finalTotal;
    }
    if (field === "quantity") {
      // const total2 =
      //   (updatedFields[index].weight ?? 1) *
      //   (updatedFields[index].price ?? 1) *
      //   value;
      // updatedFields[index].total =
      //   Math.round((total2 + total2 * 0.03) * 100) / 100;
      const total =
        (updatedFields[index].price ?? 1) *
        (updatedFields[index].weight ?? 1) *
        value;
      let finalTotal = total;
      updatedFields[index].total_without_tax = total;
      updatedFields[index].bill_taxes.forEach((val, i) => {
        finalTotal += Math.round((total * val.tax_percentage) / 100);
        updatedFields[index].bill_taxes[i].tax_amount = Math.round(
          (total * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = finalTotal;
    }
    if (field === "price") {
      // const total3 =
      //   (updatedFields[index].quantity ?? 1) *
      //   (updatedFields[index].weight ?? 1) *
      //   value;
      // updatedFields[index].total =
      //   Math.round((total3 + total3 * 0.03) * 100) / 100;
      const total =
        (updatedFields[index].weight ?? 1) *
        (updatedFields[index].quantity ?? 1) *
        value;
      let finalTotal = total;
      updatedFields[index].total_without_tax = total;
      updatedFields[index].bill_taxes.forEach((val, i) => {
        finalTotal += Math.round((total * val.tax_percentage) / 100);
        updatedFields[index].bill_taxes[i].tax_amount = Math.round(
          (total * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = finalTotal;
    }
    updatedFields[index][field] = value;
    setFieldValue("Jewelry", updatedFields);
  };

  const handleChangeJewelryTax = (index, indexTax, field, value) => {
    let updatedFields = values.Jewelry;
    updatedFields[index].bill_taxes[indexTax][field] = value;

    if (field === "tax_name") {
      const taxPercentage = taxData?.taxList.filter(
        (val) => val.tax_name === value
      );
      updatedFields[index].bill_taxes[indexTax].tax_percentage =
        taxPercentage[0]?.tax_percentage;
    }
    if (field === "tax_percentage") {
      updatedFields[index].bill_taxes[indexTax].tax_amount = Math.round(
        (updatedFields[index].total_without_tax * value) / 100
      );
    }
    let total = updatedFields[index].total_without_tax;
    updatedFields[index].bill_taxes.forEach((val, i) => {
      total += Math.round(
        (updatedFields[index].total_without_tax * val.tax_percentage) / 100
      );
      updatedFields[index].bill_taxes[i].tax_amount = Math.round(
        (updatedFields[index].total_without_tax * val.tax_percentage) / 100
      );
    });
    updatedFields[index].total = total;
    setFieldValue("Jewelry", updatedFields);
  };
  const handleDeleteFieldJewelryTax = async (index, indexTax, id) => {
    if (id) {
      await dispatch(deleteBillTax(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Jewelry;
            updatedFields?.[index]?.bill_taxes.splice(indexTax, 1);
            let total = updatedFields[index].total_without_tax;
            updatedFields[index].bill_taxes.forEach((val) => {
              total += Math.round(
                (updatedFields[index].total_without_tax * val.tax_percentage) /
                  100
              );
            });
            updatedFields[index].total = total;
            setFieldValue("Jewelry", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Jewelry;
      updatedFields?.[index]?.bill_taxes.splice(indexTax, 1);
      let total = updatedFields[index].total_without_tax;
      updatedFields[index].bill_taxes.forEach((val) => {
        total += Math.round(
          (updatedFields[index].total_without_tax * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = total;
      setFieldValue("Jewelry", updatedFields);
    }
  };

  const handleAddMoreJewelryTax = (index) => {
    let updatedFields = values.Jewelry;
    updatedFields[index]?.bill_taxes.push({
      tax_name: "",
      tax_percentage: 0,
      tax_amount: 0,
      item_amount: 0,
    });
    setFieldValue("Jewelry", updatedFields);
  };

  const handleAddMore = () => {
    formik.setValues({
      ...formik.values,
      Jewelry: [
        ...formik.values.Jewelry,
        {
          id: null,
          product_id: "",
          item: null,
          hsn_sac: 0,
          gst_percentage: 1.5,
          carat: "",
          weight: 0,
          quantity: 0,
          price: 0,
          total: 0,
          bill_taxes: [],
        },
      ],
    });
  };

  const handleDeleteField = async (index, id) => {
    if (id) {
      await dispatch(deleteBillItem(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Jewelry;
            updatedFields.splice(index, 1);
            setFieldValue("Jewelry", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Jewelry;
      updatedFields.splice(index, 1);
      setFieldValue("Jewelry", updatedFields);
    }
  };

  const handleChangeDiamond = (index, field, value) => {
    let updatedFields = values.Diamond;

    if (field === "quantity") {
      // const total1 = (updatedFields[index].price ?? 1) * value;
      // updatedFields[index].total =
      //   Math.round((total1 + total1 * 0.015) * 100) / 100;

      const total = (updatedFields[index].price ?? 1) * value;
      let finalTotal = total;
      updatedFields[index].total_without_tax = total;
      updatedFields[index].bill_taxes.forEach((val, i) => {
        finalTotal += Math.round((total * val.tax_percentage) / 100);
        updatedFields[index].bill_taxes[i].tax_amount = Math.round(
          (total * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = finalTotal;
    }
    if (field === "price") {
      // const total2 = (updatedFields[index].quantity ?? 1) * value;
      // updatedFields[index].total =
      //   Math.round((total2 + total2 * 0.015) * 100) / 100;
      const total = (updatedFields[index].quantity ?? 1) * value;
      let finalTotal = total;
      updatedFields[index].total_without_tax = total;
      updatedFields[index].bill_taxes.forEach((val, i) => {
        finalTotal += Math.round((total * val.tax_percentage) / 100);
        updatedFields[index].bill_taxes[i].tax_amount = Math.round(
          (total * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = finalTotal;
    }
    updatedFields[index][field] = value;
    setFieldValue("Diamond", updatedFields);
  };

  const handleChangeDiamondTax = (index, indexTax, field, value) => {
    let updatedFields = values.Diamond;
    updatedFields[index].bill_taxes[indexTax][field] = value;

    if (field === "tax_name") {
      const taxPercentage = taxData?.taxList.filter(
        (val) => val.tax_name === value
      );
      updatedFields[index].bill_taxes[indexTax].tax_percentage =
        taxPercentage[0]?.tax_percentage;
    }
    if (field === "tax_percentage") {
      updatedFields[index].bill_taxes[indexTax].tax_amount = Math.round(
        (updatedFields[index].total_without_tax * value) / 100
      );
    }
    let total = updatedFields[index].total_without_tax;
    updatedFields[index].bill_taxes.forEach((val, i) => {
      total += Math.round(
        (updatedFields[index].total_without_tax * val.tax_percentage) / 100
      );
      updatedFields[index].bill_taxes[i].tax_amount = Math.round(
        (updatedFields[index].total_without_tax * val.tax_percentage) / 100
      );
    });
    updatedFields[index].total = total;
    setFieldValue("Diamond", updatedFields);
  };

  const handleDeleteFieldDiamondTax = async (index, indexTax, id) => {
    if (id) {
      await dispatch(deleteBillTax(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Diamond;
            updatedFields?.[index]?.bill_taxes.splice(indexTax, 1);
            let total = updatedFields[index].total_without_tax;
            updatedFields[index].bill_taxes.forEach((val) => {
              total += Math.round(
                (updatedFields[index].total_without_tax * val.tax_percentage) /
                  100
              );
            });
            updatedFields[index].total = total;
            setFieldValue("Diamond", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Diamond;
      updatedFields?.[index]?.bill_taxes.splice(indexTax, 1);
      let total = updatedFields[index].total_without_tax;
      updatedFields[index].bill_taxes.forEach((val) => {
        total += Math.round(
          (updatedFields[index].total_without_tax * val.tax_percentage) / 100
        );
      });
      updatedFields[index].total = total;
      setFieldValue("Diamond", updatedFields);
    }
  };

  const handleAddMoreDiamondTax = (index) => {
    let updatedFields = values.Diamond;
    updatedFields[index]?.bill_taxes.push({
      tax_name: "",
      tax_percentage: 0,
      tax_amount: 0,
      item_amount: 0,
    });
    setFieldValue("Diamond", updatedFields);
  };

  const handleAddMoreDiamond = () => {
    formik.setValues({
      ...formik.values,
      Diamond: [
        ...formik.values.Diamond,
        {
          id: null,
          product_id: "",
          item: null,
          hsn_sac: 0,
          gst_percentage: 1.5,
          weight: 0,
          quantity: 0,
          price: 0,
          total: 0,
          bill_taxes: [],
        },
      ],
    });
  };

  const handleDeleteFieldDiamond = async (index, id) => {
    if (id) {
      await dispatch(deleteBillItem(id))
        .unwrap()
        .then((res) => {
          if (res?.status === 200) {
            enqueueSnackbar(res.data?.response_message, {
              variant: "success",
            });
            let updatedFields = values.Diamond;
            updatedFields.splice(index, 1);
            setFieldValue("Diamond", updatedFields);
          } else {
            enqueueSnackbar(res.data?.response_message, {
              variant: "error",
            });
          }
        });
    } else {
      let updatedFields = values.Diamond;
      updatedFields.splice(index, 1);
      setFieldValue("Diamond", updatedFields);
    }
  };

  useEffect(() => {
    dispatch(getLastInvoiceAndBillNo(localStorage.getItem("store_id")));
    dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
    dispatch(getCategoryList());
    dispatch(getBillUniqDescriptionList());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(editBill(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            const billDetail = response?.data?.data?.bill_detail;
            setVendor(billDetail?.supplier);
            setCategory(billDetail?.category);
            // dispatch(
            //   getProductList({
            //     category_id: billDetail?.category_id,
            //     store_id: localStorage.getItem("store_id"),
            //     module: "bill",
            //   })
            // );
            setFieldValue(
              "bill_date",
              moment(billDetail?.bill_date).format("YYYY-MM-DD")
            );
            if (billDetail?.due_date) {
              setFieldValue(
                "due_date",
                moment(billDetail?.due_date).format("YYYY-MM-DD")
              );
            }
            setFieldValue("bill_number", billDetail?.bill_number);
            setFieldValue("vendor_id", billDetail?.supplier_id);
            setFieldValue("category_id", billDetail?.category_id);
            dispatch(
              getStoreTaxList({
                id: localStorage.getItem("store_id"),
                data: { category_id: billDetail?.category_id },
              })
            );
            if (billDetail?.category_id === 1) {
              setFieldValue("Jewelry", billDetail?.bill_item);
            }
            if (
              billDetail?.category_id === 2 ||
              billDetail?.category_id === 3
            ) {
              setFieldValue("Diamond", billDetail?.bill_item);
            }
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    }
  }, [dispatch, enqueueSnackbar, id, setFieldValue]);

  const { handleSubmit } = formik;
  return (
    <PageContainer
      heading={id ? "Update Bill" : "Add Bill"}
      breadcrumb={breadcrumbData}
    >
      <Card>
        <CardContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container border={1}>
                <Grid item sm={1.5} xs={12} borderRight={{ xs: 0, sm: 1 }}>
                  <Grid>
                    <Grid px={1} pt={1} mb={1}>
                      <JumboAutoComplete
                        options={categoryData?.list ?? []}
                        label="Category*"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="category_id"
                        onChange={(evt, value) => {
                          setFieldValue("category_id", value?.id);
                          if (value?.id) {
                            dispatch(
                              getStoreTaxList({
                                id: localStorage.getItem("store_id"),
                                data: { category_id: value?.id },
                              })
                            );
                          }
                          setCategory(value);
                          // if (value?.id)
                          //   dispatch(
                          //     getProductList({
                          //       category_id: value?.id,
                          //       store_id: localStorage.getItem("store_id"),
                          //       module: "bill",
                          //     })
                          //   );
                        }}
                        disabled={id}
                        value={category}
                        error={formik?.errors?.category_id}
                        helperText={formik?.errors?.category_id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={12} borderRight={{ xs: 0, sm: 1 }}>
                  <Grid>
                    <Grid px={1} pt={1} mb={1}>
                      <JumboAutoComplete
                        options={supplierData.list}
                        label="Vendor*"
                        size="small"
                        getOptionLabel={(option) => option.name}
                        name="vendor_id"
                        onChange={(evt, value) => {
                          setFieldValue("vendor_id", value?.id);
                          setVendor(value);
                        }}
                        value={vendor}
                        error={formik?.errors?.vendor_id}
                        helperText={formik?.errors?.vendor_id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={7.5} xs={12}>
                  <Grid container>
                    {!id && (
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        borderRight={{ xs: 0, sm: 1 }}
                        borderBottom={1}
                        p={1}
                      >
                        <TextField
                          label={"Last Bill No."}
                          name="bill_number"
                          fullWidth
                          size="small"
                          value={BillData?.lastBillNo}
                          disabled={true}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      sm={id ? 4 : 3}
                      xs={12}
                      borderRight={{ xs: 0, sm: 1 }}
                      borderBottom={1}
                      p={1}
                    >
                      <TextField
                        label={"Bill No.*"}
                        name="bill_number"
                        fullWidth
                        size="small"
                        value={values?.bill_number}
                        onChange={(evt) =>
                          setFieldValue("bill_number", evt.target.value)
                        }
                        disabled={id}
                        error={errors?.bill_number}
                        helperText={errors?.bill_number}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={id ? 4 : 3}
                      xs={12}
                      borderBottom={1}
                      p={1}
                      borderRight={{ xs: 0, sm: 1 }}
                    >
                      <TextField
                        label={"Date*"}
                        name="bill_date"
                        fullWidth
                        size="small"
                        type="date"
                        onChange={(evt) =>
                          setFieldValue("bill_date", evt.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        value={values?.bill_date}
                        error={errors?.bill_date}
                        helperText={errors?.bill_date}
                      />
                    </Grid>
                    <Grid item sm={id ? 4 : 3} xs={12} borderBottom={1} p={1}>
                      <TextField
                        label={"Due Date"}
                        name="due_date"
                        fullWidth
                        size="small"
                        type="date"
                        onChange={(evt) =>
                          setFieldValue("due_date", evt.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        value={values?.due_date}
                        error={errors?.due_date}
                        helperText={errors?.due_date}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} borderRight={1} borderBottom={1}>
                <TableContainer component={Paper}>
                  {values?.category_id === 1 && (
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                      className={classes?.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell className={classes?.product}>
                            Product/Description
                          </TableCell>
                          <TableCell>HSN/SAC</TableCell>
                          <TableCell>Tax</TableCell>
                          <TableCell>Carat</TableCell>
                          <TableCell>Weight Per Qty (gm)</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Price per gm</TableCell>
                          <TableCell className={classes?.amount}>
                            Total
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {serviceLengthJewelry &&
                          values?.Jewelry.map((item, index) => {
                            sum += item?.total ? +item?.total : 0;
                            return (
                              <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className={classes?.product}>
                                  <Div className={classes?.margin} key={index}>
                                    {(item.product || item.item) &&
                                    item.createdAt ? (
                                      <TextField
                                        value={
                                          item?.product?.name
                                            ? item?.product?.name
                                            : item.item
                                        }
                                        size="small"
                                      />
                                    ) : (
                                      <JumboAutoComplete
                                        options={
                                          billDescriptionData?.descriptionList ??
                                          []
                                        }
                                        label="Product/Description*"
                                        size="small"
                                        getOptionLabel={(option) => {
                                          if (typeof option === "string") {
                                            return option;
                                          }
                                          if (option.inputValue) {
                                            return option.inputValue;
                                          }
                                          return option.name;
                                        }}
                                        filterOptions={(options, params) => {
                                          const filtered = filter(
                                            options,
                                            params
                                          );
                                          const { inputValue } = params;
                                          const isExisting = options.some(
                                            (option) =>
                                              inputValue === option.name
                                          );
                                          if (
                                            inputValue !== "" &&
                                            !isExisting
                                          ) {
                                            filtered.push({
                                              inputValue,
                                              name: `Add "${inputValue}"`,
                                            });
                                          }
                                          return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        name="product_id"
                                        onChange={(evt, value) => {
                                          handleChange(
                                            index,
                                            "product_id",
                                            null
                                          );
                                          // handleChange(
                                          //   index,
                                          //   "carat",
                                          //   value?.carat ?? ""
                                          // );
                                          handleChange(
                                            index,
                                            "item",
                                            value?.inputValue ?? value?.name
                                          );
                                          // handleChange(
                                          //   index,
                                          //   "hsn_sac",
                                          //   value?.hsn ?? ""
                                          // );
                                        }}
                                        renderOption={(props, option) => {
                                          const { key, ...optionProps } = props;
                                          return (
                                            <li key={key} {...optionProps}>
                                              {option.name}
                                            </li>
                                          );
                                        }}
                                        freeSolo
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Free solo with text demo"
                                          />
                                        )}
                                        error={
                                          formik?.errors?.Jewelry?.length &&
                                          formik?.errors?.Jewelry[index]
                                            ?.product_id
                                        }
                                        helperText={
                                          formik?.errors?.Jewelry?.length &&
                                          formik?.errors?.Jewelry[index]
                                            ?.product_id
                                        }
                                      />
                                    )}
                                    <Grid container spacing={1.5}>
                                      {item?.bill_taxes?.map(
                                        (taxVal, indexTax) => {
                                          return (
                                            <>
                                              <Grid item sm={6.5} mt={1}>
                                                <JumboSelectBox
                                                  data={taxData?.taxList ?? []}
                                                  id="id"
                                                  name={"tax_name"}
                                                  size="small"
                                                  labelKey="tax_name"
                                                  valueKey="tax_name"
                                                  label="Taxes*"
                                                  required
                                                  value={taxVal?.tax_name}
                                                  onChange={(evt) =>
                                                    handleChangeJewelryTax(
                                                      index,
                                                      indexTax,
                                                      "tax_name",
                                                      evt.target.value
                                                    )
                                                  }
                                                  error={
                                                    errors?.Jewelry?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_name
                                                  }
                                                  helperText={
                                                    errors?.Jewelry?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_name
                                                  }
                                                />
                                              </Grid>
                                              <Grid item sm={4} mt={1}>
                                                <TextField
                                                  label={"Tax (%)*"}
                                                  name={`tax_percentage`}
                                                  fullWidth
                                                  size="small"
                                                  value={taxVal?.tax_percentage}
                                                  onChange={(evt) =>
                                                    handleChangeJewelryTax(
                                                      index,
                                                      indexTax,
                                                      "tax_percentage",
                                                      +evt.target.value
                                                    )
                                                  }
                                                  type="number"
                                                  error={
                                                    errors?.Jewelry?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_percentage
                                                  }
                                                  helperText={
                                                    errors?.Jewelry?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Jewelry[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_percentage
                                                  }
                                                />
                                              </Grid>
                                              <Grid item sm={1.5} mt={1}>
                                                <IconButton
                                                  onClick={() => {
                                                    handleDeleteFieldJewelryTax(
                                                      index,
                                                      indexTax,
                                                      taxVal?.id
                                                    );
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </Div>
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"HSN/SAC*"}
                                    name={`hsn_sac[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.hsn_sac}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "hsn_sac",
                                        +evt.target.value
                                      )
                                    }
                                    // disabled={item?.product_id}
                                    type="number"
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.hsn_sac
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.hsn_sac
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.gst}>
                                  <IconButton
                                    onClick={() => {
                                      handleAddMoreJewelryTax(index);
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                  <Grid container spacing={1.5}>
                                    {item?.bill_taxes?.map(
                                      (taxVal, indexTax) => {
                                        return (
                                          <Grid item sm={12} mt={1}>
                                            <TextField
                                              label={"Tax amt"}
                                              name={`tax_amount`}
                                              fullWidth
                                              size="small"
                                              type="number"
                                              value={taxVal?.tax_amount}
                                              disabled
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Carat*"}
                                    name={`carat[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.carat}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "carat",
                                        evt.target.value
                                      )
                                    }
                                    disabled={
                                      item.createdAt ? true : !item?.item
                                    }
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.carat
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.carat
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Weight*"}
                                    name={`weight[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.weight}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "weight",
                                        +evt.target.value
                                      )
                                    }
                                    disabled={id && item?.id}
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_weight ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.weight
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.weight
                                    }
                                  />
                                </TableCell>

                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Quantity*"}
                                    name={`quantity[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.quantity}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "quantity",
                                        +evt.target.value
                                      )
                                    }
                                    disabled={id && item?.id}
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_quantity ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.quantity
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.quantity
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Price per gm*"}
                                    name={`price[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.price}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "price",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.price
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.price
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Total*"}
                                    name={`total[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.total}
                                    onChange={(evt) =>
                                      handleChange(
                                        index,
                                        "total",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.total
                                    }
                                    helperText={
                                      errors?.Jewelry?.length &&
                                      errors?.Jewelry[index]?.total
                                    }
                                    disabled
                                  />
                                </TableCell>

                                <TableCell>
                                  {(index !== 0 || id) && (
                                    <IconButton
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() =>
                                        handleDeleteField(index, item?.id)
                                      }
                                    >
                                      <Cancel />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {serviceLengthJewelry && (
                          <TableRow>
                            <TableCell />
                            <TableCell
                              colSpan={7}
                              className={classes?.headingContent}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              className={classes?.headingContent}
                            >
                              {Math.round((sum ?? 0) * 100) / 100}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell colSpan={9}>
                            <Grid className={classes?.buttonContainer}>
                              <Button
                                type="button"
                                buttonLabel={"Add"}
                                sx={{ marginRight: "10px" }}
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={handleAddMore}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {serviceLengthJewelry && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Grid container>
                                <Grid item sm={11}>
                                  <Typography variant="body1">
                                    Amount Chargeable (in words)
                                  </Typography>
                                </Grid>
                                <Grid item sm={1}>
                                  <Typography variant="body1">
                                    E. & O.E
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography variant="subtitle2">
                                {capitalizeFirstLetter(
                                  wordConverter(Math.round(sum ?? 0))
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                  {(values?.category_id === 2 || values?.category_id === 3) && (
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                      className={classes?.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell className={classes?.particular}>
                            Product/Description
                          </TableCell>
                          <TableCell>HSN/SAC</TableCell>
                          <TableCell>Tax</TableCell>
                          <TableCell>Carat/Quantity</TableCell>
                          {/* <TableCell>Quantity</TableCell> */}
                          <TableCell>Price per Carat</TableCell>
                          <TableCell className={classes?.amount}>
                            Total
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {serviceLengthDiamond &&
                          values?.Diamond.map((item, index) => {
                            sum += item?.total ? +item?.total : 0;
                            return (
                              <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell className={classes?.product}>
                                  <Div className={classes?.margin} key={index}>
                                    {(item.product || item.item) &&
                                    item.createdAt ? (
                                      <TextField
                                        value={
                                          item?.product?.name
                                            ? item?.product?.name
                                            : item.item
                                        }
                                        size="small"
                                      />
                                    ) : (
                                      <JumboAutoComplete
                                        options={
                                          billDescriptionData?.descriptionList ??
                                          []
                                        }
                                        label="Product/Description*"
                                        size="small"
                                        getOptionLabel={(option) => {
                                          if (typeof option === "string") {
                                            return option;
                                          }
                                          if (option.inputValue) {
                                            return option.inputValue;
                                          }
                                          return option.name;
                                        }}
                                        filterOptions={(options, params) => {
                                          const filtered = filter(
                                            options,
                                            params
                                          );
                                          const { inputValue } = params;
                                          const isExisting = options.some(
                                            (option) =>
                                              inputValue === option.name
                                          );
                                          if (
                                            inputValue !== "" &&
                                            !isExisting
                                          ) {
                                            filtered.push({
                                              inputValue,
                                              name: `Add "${inputValue}"`,
                                            });
                                          }
                                          return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        name="product_id"
                                        onChange={(evt, value) => {
                                          handleChangeDiamond(
                                            index,
                                            "product_id",
                                            null
                                          );
                                          handleChangeDiamond(
                                            index,
                                            "item",
                                            value?.inputValue ?? value?.name
                                          );
                                          // handleChangeDiamond(
                                          //   index,
                                          //   "hsn_sac",
                                          //   value?.hsn ?? ""
                                          // );
                                        }}
                                        renderOption={(props, option) => {
                                          const { key, ...optionProps } = props;
                                          return (
                                            <li key={key} {...optionProps}>
                                              {option.name}
                                            </li>
                                          );
                                        }}
                                        freeSolo
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Free solo with text demo"
                                          />
                                        )}
                                        error={
                                          formik?.errors?.Diamond?.length &&
                                          formik?.errors?.Diamond[index]
                                            ?.product_id
                                        }
                                        helperText={
                                          formik?.errors?.Diamond?.length &&
                                          formik?.errors?.Diamond[index]
                                            ?.product_id
                                        }
                                      />
                                    )}
                                    <Grid container spacing={1.5}>
                                      {item?.bill_taxes?.map(
                                        (taxVal, indexTax) => {
                                          return (
                                            <>
                                              <Grid item sm={6.5} mt={1}>
                                                <JumboSelectBox
                                                  data={taxData?.taxList ?? []}
                                                  id="id"
                                                  name={"tax_name"}
                                                  size="small"
                                                  labelKey="tax_name"
                                                  valueKey="tax_name"
                                                  label="Taxes*"
                                                  required
                                                  value={taxVal?.tax_name}
                                                  onChange={(evt) =>
                                                    handleChangeDiamondTax(
                                                      index,
                                                      indexTax,
                                                      "tax_name",
                                                      evt.target.value
                                                    )
                                                  }
                                                  error={
                                                    errors?.Diamond?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_name
                                                  }
                                                  helperText={
                                                    errors?.Diamond?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_name
                                                  }
                                                />
                                              </Grid>
                                              <Grid item sm={4} mt={1}>
                                                <TextField
                                                  label={"Tax (%)*"}
                                                  name={`tax_percentage`}
                                                  fullWidth
                                                  size="small"
                                                  value={taxVal?.tax_percentage}
                                                  onChange={(evt) =>
                                                    handleChangeDiamondTax(
                                                      index,
                                                      indexTax,
                                                      "tax_percentage",
                                                      +evt.target.value
                                                    )
                                                  }
                                                  type="number"
                                                  error={
                                                    errors?.Diamond?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_percentage
                                                  }
                                                  helperText={
                                                    errors?.Diamond?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.length &&
                                                    errors?.Diamond[index]
                                                      ?.bill_taxes?.[indexTax]
                                                      ?.tax_percentage
                                                  }
                                                />
                                              </Grid>
                                              <Grid item sm={1.5} mt={1}>
                                                <IconButton
                                                  onClick={() => {
                                                    handleDeleteFieldDiamondTax(
                                                      index,
                                                      indexTax,
                                                      taxVal?.id
                                                    );
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </Div>
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"HSN/SAC*"}
                                    name={`hsn_sac[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.hsn_sac}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "hsn_sac",
                                        +evt.target.value
                                      )
                                    }
                                    type="number"
                                    // disabled={item?.product_id}
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.hsn_sac
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.hsn_sac
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.gst}>
                                  <IconButton
                                    onClick={() => {
                                      handleAddMoreDiamondTax(index);
                                    }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                  <Grid container spacing={1.5}>
                                    {item?.bill_taxes?.map(
                                      (taxVal, indexTax) => {
                                        return (
                                          <Grid item sm={12} mt={1}>
                                            <TextField
                                              label={"Tax amt"}
                                              name={`tax_amount`}
                                              fullWidth
                                              size="small"
                                              type="number"
                                              value={taxVal?.tax_amount}
                                              disabled
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </TableCell>
                                {/* <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Carat*"}
                                    name={`weight[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.weight}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "weight",
                                        +evt.target.value
                                      )
                                    }
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_weight ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    disabled={id && item?.id}
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.weight
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.weight
                                    }
                                  />
                                </TableCell> */}
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Carat/Quantity*"}
                                    name={`quantity[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.quantity}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "quantity",
                                        +evt.target.value
                                      )
                                    }
                                    InputProps={{
                                      inputProps: {
                                        max:
                                          +item?.productData
                                            ?.remaining_quantity ?? 0,
                                        min: 0,
                                      },
                                    }}
                                    disabled={id && item?.id}
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.quantity
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.quantity
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Price per carat*"}
                                    name={`price[${index}]`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    value={item?.price}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "price",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.price
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.price
                                    }
                                  />
                                </TableCell>
                                <TableCell className={classes?.particular}>
                                  <TextField
                                    label={"Total*"}
                                    name={`total[${index}]`}
                                    fullWidth
                                    size="small"
                                    value={item?.total}
                                    onChange={(evt) =>
                                      handleChangeDiamond(
                                        index,
                                        "total",
                                        +evt.target.value
                                      )
                                    }
                                    error={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.total
                                    }
                                    helperText={
                                      errors?.Diamond?.length &&
                                      errors?.Diamond[index]?.total
                                    }
                                    disabled
                                  />
                                </TableCell>

                                <TableCell>
                                  {(index !== 0 || id) && (
                                    <IconButton
                                      aria-label="delete"
                                      color="secondary"
                                      onClick={() =>
                                        handleDeleteFieldDiamond(
                                          index,
                                          item?.id
                                        )
                                      }
                                    >
                                      <Cancel />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {serviceLengthDiamond && (
                          <TableRow>
                            <TableCell />
                            <TableCell
                              colSpan={5}
                              className={classes?.headingContent}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              className={classes?.headingContent}
                            >
                              {Math.round((sum ?? 0) * 100) / 100}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell colSpan={9}>
                            <Grid className={classes?.buttonContainer}>
                              <Button
                                type="button"
                                buttonLabel={"Add"}
                                sx={{ marginRight: "10px" }}
                                startIcon={<Add />}
                                variant="outlined"
                                onClick={handleAddMoreDiamond}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {serviceLengthDiamond && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Grid container>
                                <Grid item sm={11}>
                                  <Typography variant="body1">
                                    Amount Chargeable (in words)
                                  </Typography>
                                </Grid>
                                <Grid item sm={1}>
                                  <Typography variant="body1">
                                    E. & O.E
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography variant="subtitle2">
                                {capitalizeFirstLetter(
                                  wordConverter(Math.round(sum ?? 0))
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Grid>
              <br></br>
              <Grid container spacing={3}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  className={classes?.loadingButtonContainer}
                >
                  <LoadingButton
                    type="submit"
                    buttonLabel={id ? "Update" : "Save"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(BILL)}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AddAndUpdateBill;
