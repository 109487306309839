import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add, Dashboard } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { makeStyles } from "@mui/styles";
import Table from "@jumbo/components/JumboTable";
import PageContainer from "@jumbo/components/PageContainer";
import DialogConfirm from "@jumbo/components/JumboDialog/components/DialogConfirm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  HOME,
  createBankTransferEditRoute,
  createBankTransferSettlementRoute,
} from "@jumbo/constants/routes";
import { useDisclosure } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Div from "@jumbo/shared/Div";
import DialogDefault from "@jumbo/components/JumboDialog/components/DialogDefault";
import ImportProduct from "../import";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import {
  deleteBankTransaction,
  getBankTransactionList,
} from "app/redux/slice/bankTransactionSlice";
import moment from "moment";
import { userType } from "app/helpers/userType";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <AccountBalanceWalletIcon />,
    label: "Bank Transaction",
  },
];

const BankTransfer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const deleteDisclosure = useDisclosure();
  const importDisclosure = useDisclosure();

  const dispatch = useDispatch();
  const { isMA } = userType();
  const { enqueueSnackbar } = useSnackbar();
  const bankTransactionData = useSelector((state) => state?.bankTransaction);
  const [page, setPage] = useState(0);
  const [deleted, setDeleted] = useState(false);
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    process.env.REACT_APP_PER_PAGE ?? 10
  );
  const [permissionData] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );

  const [permission, setPermission] = useState({
    importPermission: false,
    updatePermission: false,
    deletePermission: false,
    settlementPermission: false,
  });

  const getPermission = () => {
    if (isMA) {
      setPermission({
        importPermission: true,
        updatePermission: true,
        deletePermission: true,
        settlementPermission: true,
      });
    } else {
      let permissionValue = {
        importPermission: false,
        updatePermission: false,
        deletePermission: false,
        settlementPermission: false,
      };
      permissionData?.length &&
        permissionData.forEach(function (num) {
          if (num?.name === "bankTransaction-import") {
            permissionValue.importPermission = true;
          }
          if (num?.name === "bankTransaction-delete") {
            permissionValue.deletePermission = true;
          }
          if (num?.name === "bankTransaction-update") {
            permissionValue.updatePermission = true;
          }
          if (num?.name === "bankTransaction-settlement") {
            permissionValue.settlementPermission = true;
          }
        });
      setPermission(permissionValue);
    }
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const column = [
    {
      id: "transaction_date",
      label: "Date",
      render: (elm) => (
        <> {moment(elm?.transaction_date).format("DD/MM/YYYY")}</>
      ),
    },
    { id: "party_name", label: "Name" },
    { id: "remark", label: "Remark" },
    {
      label: "Dr",
      render: (elm) => (elm?.transaction_type === "debit" ? elm?.amount : "-"),
    },
    {
      label: "Cr",
      render: (elm) => (elm?.transaction_type !== "debit" ? elm?.amount : "-"),
    },
    {
      label: "Actions",
      key: "actionIcon",
      sort: false,
      align: "right",
      render: (elm) => (
        <>
          {permission.updatePermission && (
            <IconButton
              onClick={() => {
                navigate(createBankTransferEditRoute(elm?.id));
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {permission.deletePermission && (
            <IconButton
              onClick={() => {
                setId(elm.id);
                deleteDisclosure.onOpen();
              }}
            >
              <DeleteIcon color={"error"} />
            </IconButton>
          )}
          {permission.settlementPermission && (
            <Tooltip title="Bank Transfer Settlement">
              <IconButton
                onClick={() => {
                  navigate(createBankTransferSettlementRoute(elm?.id));
                }}
              >
                <CreditScoreIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const handleConfirm = () => {
    dispatch(deleteBankTransaction(id))
      .unwrap()
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(res.data?.response_message, {
            variant: "success",
          });
          setDeleted(!deleted);
          deleteDisclosure.onClose();
        } else {
          enqueueSnackbar(res.data?.response_message, {
            variant: "error",
          });
        }
      });
    deleteDisclosure.onClose();
  };

  const getData = () => {
    const value = {
      page: page + 1,
      per_page: rowsPerPage,
      search: searchText.trim(),
      store_id: localStorage.getItem("store_id"),
      is_settled: 1,
    };
    dispatch(getBankTransactionList(value));
  };

  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      getData();
    } else {
      setView(false);
      enqueueSnackbar("Please select store.", { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, page, rowsPerPage, dispatch]);

  return (
    <PageContainer heading="Bank Transaction" breadcrumb={breadcrumbData}>
      <Paper className={classes?.filterContainer}>
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={10}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Search"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      setPage(0);
                      getData();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      {view && (
        <Paper>
          {permission.importPermission && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="outlined"
                startIcon={<Add />}
                className={classes?.buttonPadding}
                onClick={() => {
                  importDisclosure.onOpen();
                }}
              >
                Import
              </Button>
            </Box>
          )}

          <Table
            columns={column}
            rows={bankTransactionData.list}
            count={bankTransactionData.total}
            setPage={setPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      )}

      <DialogConfirm
        open={deleteDisclosure?.isOpen}
        title="Delete Bank Transaction"
        message="Are you sure you want to delete this Bank Transaction ?"
        onConfirm={handleConfirm}
        onClose={deleteDisclosure.onClose}
      />
      <DialogDefault
        open={importDisclosure?.isOpen}
        onClose={importDisclosure.onClose}
        content={
          <ImportProduct
            onClose={importDisclosure.onClose}
            getData={getData}
            link={process.env.REACT_APP_SAMPLE_FILE ?? "/bank-transfer"}
          />
        }
        title={"Import File"}
        fullWidth={true}
        maxWidth={"sm"}
        actions={<></>}
      />
    </PageContainer>
  );
};

export default BankTransfer;
