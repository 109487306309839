import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dashboard, Save, Close } from "@mui/icons-material";
import { Card, CardContent, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import TextField from "@jumbo/components/JumboFormik/JumboTextField";
import PageContainer from "@jumbo/components/PageContainer";
import LoadingButton from "@jumbo/components/JumboLoadingButton";
import { HOME, BANK_TRANSFER } from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import JumboSelectBox from "@jumbo/components/JumboSelectBox";
import moment from "moment";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { getCustomerList } from "app/redux/slice/customerSlice";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import Table from "@jumbo/components/JumboTable";
import { getPartiList } from "app/redux/slice/partiSlice";
import { getExpenseTypeList } from "app/redux/slice/expenseTypeSlice";
import {
  editBankTransaction,
  settlementBankTransaction,
} from "app/redux/slice/bankTransactionSlice";
import { getInvoiceList } from "app/redux/slice/invoiceSlice";
import { getBillList } from "app/redux/slice/billSlice";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
  },
});

const validationSchemaInvoice = yup.object({
  customer: yup.object().nullable().required("Please select customer."),
  invoice: yup.object().nullable().required("Please select invoice."),
});

const validationSchema = yup.object().shape({
  paymentFor: yup.number().nullable().required("Payment for is required."),
  supplier: yup
    .object()
    .nullable()
    .when("paymentFor", {
      is: 1,
      then: yup.object().nullable().required("Please select supplier."),
      otherwise: yup.object().nullable(),
    }),
  bill: yup
    .object()
    .nullable()
    .when("paymentFor", {
      is: 1,
      then: yup.object().nullable().required("Please select bill."),
      otherwise: yup.object().nullable(),
    }),
  expense_type_id: yup
    .object()
    .nullable()
    .when("paymentFor", {
      is: 1,
      then: yup.object().nullable(),
      otherwise: yup
        .object()
        .nullable()
        .required("Please select expense type."),
    }),
});

const Settlement = () => {
  const classes = useStyles();
  const { id } = useParams();
  const supplierData = useSelector((state) => state?.supplier);
  const customerData = useSelector((state) => state?.customer);
  const expenseTypeData = useSelector((state) => state?.expenseType);
  const navigate = useNavigate();
  const mutation = { isError: false };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState();
  const [amount, setAmount] = useState();
  const [invoiceData, setInvoiceData] = useState([]);
  const [billData, setBillData] = useState([]);

  const breadcrumbData = [
    {
      path: HOME,
      icon: <Dashboard />,
      label: "Dashboard",
    },
    {
      path: BANK_TRANSFER,
      icon: <AccountBalanceWalletIcon />,
      label: "Bank Transaction",
    },
    {
      icon: <CreditScoreIcon />,
      label: "Settlement",
    },
  ];

  const formik = useFormik({
    initialValues: {
      amount: 0,
      paymentFor: 1,
      expense_type_id: null,
      customer: null,
      supplier: null,
      bill: null,
      invoice: null,
    },
    validationSchema:
      type === "credit" ? validationSchemaInvoice : validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
      setSubmitting(true);
      let settlementData = {
        bank_transaction_id: id,
      };
      if (type === "credit") {
        settlementData.invoice_id = values?.invoice?.id;
      } else {
        if (values?.paymentFor === 1) {
          settlementData.bill_id = values?.bill?.id;
        } else {
          settlementData.expense_type_id = values?.expense_type_id?.id;
        }
      }

      const response = await dispatch(
        settlementBankTransaction(settlementData)
      ).unwrap();
      if (response.status === 200) {
        enqueueSnackbar(response.data?.response_message, {
          variant: "success",
        });
        navigate(BANK_TRANSFER);
        resetForm();
      } else {
        enqueueSnackbar(response.data?.response_message, {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });

  const invoiceColumn = [
    { id: "invoice_number", label: "Invoice No." },
    {
      id: "date",
      label: "Invoice Date",
      render: (elm) => <> {moment(elm?.invoice_date).format("DD/MM/YYYY")}</>,
    },
    { id: "invoice_amount", label: "Invoice Amount" },
    { id: "pending_amount", label: "Pending Amount" },
    {
      label: "Amount Received",
      minWidth: 130,
      render: (elm, i) => <> {amount}</>,
    },
    {
      label: "Due Amount",
      render: (elm) => {
        return elm.pending_amount - amount;
      },
    },
  ];
  const billColumn = [
    { id: "bill_number", label: "Bill No." },
    {
      id: "date",
      label: "Bill Date",
      render: (elm) => <> {moment(elm?.bill_date).format("DD/MM/YYYY")}</>,
    },
    { id: "bill_amount", label: "Bill Amount" },
    { id: "pending_amount", label: "Pending Amount" },
    {
      label: "Amount Paid",
      minWidth: 130,
      render: (elm, i) => <> {amount}</>,
    },
    {
      label: "Due Amount",
      render: (elm) => {
        return elm.pending_amount - amount;
      },
    },
  ];
  useEffect(() => {
    if (id) {
      dispatch(editBankTransaction(id))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            setType(response?.data?.data?.existing_expense?.transaction_type);
            setAmount(response?.data?.data?.existing_expense?.amount);
            setFieldValue(
              "amount",
              response?.data?.data?.existing_expense?.amount
            );
          } else {
            enqueueSnackbar(response.data?.response_message, {
              variant: "error",
            });
          }
        });
    }
    dispatch(getCustomerList({ store_id: localStorage.getItem("store_id") }));
    dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
    dispatch(getExpenseTypeList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const customerHandleChange = (customer_id) => {
    dispatch(getInvoiceList({ customer_id, amount }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setInvoiceData(response?.data?.data?.invoice_list);
        }
      });
  };
  const supplierHandleChange = (supplier_id) => {
    dispatch(getBillList({ supplier_id, amount }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setBillData(response?.data?.data?.bill_list);
        }
      });
  };

  const { setFieldValue, handleSubmit, isSubmitting, values } = formik;

  return (
    <>
      <PageContainer
        heading={"Bank Transaction Settlement"}
        breadcrumb={breadcrumbData}
      >
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {mutation.isError && <p>{mutation.error.message}</p>}
                {type === "credit" && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <JumboAutoComplete
                          options={customerData?.list}
                          label="Customer*"
                          size="large"
                          getOptionLabel={(option) => option.name}
                          name="customer"
                          onChange={(evt, value) => {
                            setFieldValue("customer", value);
                            setFieldValue("invoice", null);
                            if (value?.id) customerHandleChange(value?.id);
                          }}
                          error={formik?.errors?.customer}
                          helperText={formik?.errors?.customer}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <JumboAutoComplete
                          options={invoiceData}
                          label="Invoice*"
                          size="large"
                          getOptionLabel={(option) => option.invoice_number}
                          name="invoice"
                          value={values?.invoice}
                          onChange={(evt, value) => {
                            setFieldValue("invoice", value);
                          }}
                          error={formik?.errors?.invoice}
                          helperText={formik?.errors?.invoice}
                        />
                      </Grid>
                    </Grid>
                    {values.invoice && (
                      <Grid item sm={12} mt={3}>
                        <Paper>
                          <Table
                            columns={invoiceColumn}
                            rows={values.invoice ? [values.invoice] : []}
                          />
                        </Paper>
                      </Grid>
                    )}
                  </>
                )}
                {type === "debit" && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <JumboSelectBox
                          fullWidth
                          name="paymentFor"
                          label="Payment For*"
                          data={[
                            {
                              id: 1,
                              name: "Bills",
                            },
                            {
                              id: 2,
                              name: "Expense",
                            },
                          ]}
                          size="large"
                          labelKey="name"
                          valueKey="id"
                          value={values?.paymentFor}
                          onChange={(evt) => {
                            setFieldValue("paymentFor", evt.target.value);
                            setFieldValue("supplier", null);
                            setFieldValue("expense_type_id", null);
                            setFieldValue("bill", null);
                          }}
                        />
                      </Grid>
                      {values?.paymentFor === 1 ? (
                        <>
                          <Grid item xs={12} sm={4}>
                            <JumboAutoComplete
                              options={supplierData?.list}
                              label="Vendor*"
                              size="large"
                              getOptionLabel={(option) => option.name}
                              name="supplier"
                              value={values?.supplier}
                              onChange={(evt, value) => {
                                setFieldValue("supplier", value);
                                setFieldValue("bill", null);
                                if (value?.id) supplierHandleChange(value?.id);
                              }}
                              error={formik?.errors?.supplier}
                              helperText={formik?.errors?.supplier}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <JumboAutoComplete
                              options={billData}
                              label="Bill*"
                              size="large"
                              getOptionLabel={(option) => option.bill_number}
                              name="bill"
                              value={values?.bill}
                              onChange={(evt, value) =>
                                setFieldValue("bill", value)
                              }
                              error={formik?.errors?.bill}
                              helperText={formik?.errors?.bill}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={4} xs={12}>
                            <TextField
                              label={"Amount"}
                              name="amount"
                              type="number"
                              fullWidth
                              required
                              disabled
                              value={values?.amount}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <JumboAutoComplete
                              options={expenseTypeData?.list}
                              label="Expense Type*"
                              size="large"
                              getOptionLabel={(option) => option.name}
                              name="expense_type_id"
                              value={values?.expense_type_id}
                              onChange={(evt, value) =>
                                setFieldValue("expense_type_id", value)
                              }
                              error={formik?.errors?.expense_type_id}
                              helperText={formik?.errors?.expense_type_id}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {values.bill && values?.paymentFor === 1 && (
                      <Grid item sm={12} mt={3}>
                        <Paper>
                          <Table
                            columns={billColumn}
                            rows={values.bill ? [values.bill] : []}
                          />
                        </Paper>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item sm={12} className={classes?.buttonContainer}>
                  <LoadingButton
                    buttonLabel={"Save"}
                    sx={{ marginRight: "10px" }}
                    startIcon={<Save />}
                    variant="outlined"
                    loading={isSubmitting || mutation.isLoading}
                    type="submit"
                  />
                  <LoadingButton
                    color={"secondary"}
                    buttonLabel={"Close"}
                    variant="outlined"
                    startIcon={<Close />}
                    onClick={() => navigate(BANK_TRANSFER)}
                  />
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default Settlement;
