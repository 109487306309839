import React, { useState } from "react";

import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import { CircularProgress, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { importBankTransaction } from "app/redux/slice/bankTransactionSlice";
import { useDispatch } from "react-redux";

const ImportProduct = ({ onClose, getData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [file, setFile] = useState([]);

  return (
    <>
      <a
        href={"/Bank-Transaction-Sample.xlsx"}
        download="Bank-Transaction-Sample.xlsx"
      >
        Download Sample File
      </a>
      <Formik
        initialValues={{ file: "" }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);

          if (!file.length) {
            enqueueSnackbar("Please select file.", { variant: "error" });
            await setSubmitting(false);
          } else {
            setSubmitting(true);
            let formData = new FormData();
            formData.append("file", file[0]);
            formData.append("store_id", localStorage.getItem("store_id"));
            await dispatch(importBankTransaction(formData))
              .unwrap()
              .then((response) => {
                if (response.status === 200) {
                  enqueueSnackbar(response.data?.response_message, {
                    variant: "success",
                  });
                  getData();
                  onClose();
                } else {
                  enqueueSnackbar(response.data?.response_message, {
                    variant: "error",
                  });
                }
              });
            // );
            await setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <TextField
              sx={{ mb: 2, mt: 1 }}
              fullWidth
              type="file"
              name="file"
              inputProps={{ accept: ".xlsx" }}
              onChange={(evt) => setFile(evt.target.files)}
            />
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              justifyContent="flex-end"
            >
              <LoadingButton
                variant="outlined"
                color="secondary"
                onClick={onClose}
                size="large"
                sx={{ mb: 3 }}
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSubmitting}
                loadingIndicator={<CircularProgress size={20} />}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ImportProduct;
