import React, { lazy } from "react";
import {
  CASH_CREDIT_DEBIT,
  CASH_BOOK,
  CHEQUE_BOOK,
  SUPPLIER,
  SUPPLIER_ADD,
  INVOICE,
  INVOICE_ADD,
  INVOICE_EDIT,
  CHEQUE_CREDIT_DEBIT,
  SUPPLIER_EDIT,
  BILL,
  BILL_ADD,
  BILL_EDIT,
  EDIT_PROFILE,
  ON_ACCOUNT,
  ON_ACCOUNT_ADD,
  EXPENSE_TYPE,
  LOGS,
  CUSTOMER,
  CUSTOMER_ADD,
  CUSTOMER_EDIT,
  LEDGER,
  CASH_SELL,
  CASH_SELL_EDIT,
  CASH_SELL_ADD,
  BANK_TRANSFER,
  BANK_TRANSFER_EDIT,
  BANK_TRANSFER_SETTLEMENT,
} from "@jumbo/constants/routes";
import PrivateRoute from "./PrivateRoute";
import Bill from "app/pages/bill/view";
import AddAndUpdateBill from "app/pages/bill/addAndUpdate";
import EditProfile from "app/pages/auth/EditProfile";
import Error401 from "app/pages/401";
import OnAccount from "app/pages/onAccount/view";
import AddOnAccount from "app/pages/onAccount/add";
import ExpenseType from "app/pages/expenseType/view";
import Logs from "app/pages/logs/view";
import Customer from "app/pages/Customer/view";
import AddAndUpdateCustomer from "app/pages/Customer/addAndUpdate";
import Ledger from "app/pages/ledger/view";
import CashSell from "app/pages/cashSell/view";
import AddAndUpdateCashSell from "app/pages/cashSell/addAndUpdate";
import BankTransfer from "app/pages/bankTransfer/view";
import AddAndUpdateBankTransfer from "app/pages/bankTransfer/addAndUpdate";
import Settlement from "app/pages/bankTransfer/settlement";

const Page = lazy(() => import("@jumbo/shared/Page"));
const CashAndCheque = lazy(() => import("../pages/cashAndCheque/view"));
const CreditAndDebit = lazy(() =>
  import("../pages/cashAndCheque/creditAndDebit")
);
const Vendor = lazy(() => import("app/pages/vendor/view"));
const AddAndUpdateVendor = lazy(() => import("app/pages/vendor/addAndUpdate"));
const Invoice = lazy(() => import("../pages/invoice/view"));
const AddAndUpdateInvoice = lazy(() => import("../pages/invoice/addAndUpdate"));

const dashboardRoutes = [
  {
    path: "401",
    element: (
      <PrivateRoute>
        <Page component={Error401} />
      </PrivateRoute>
    ),
  },
  {
    path: SUPPLIER,
    element: (
      <PrivateRoute>
        <Page component={Vendor} />
      </PrivateRoute>
    ),
  },
  {
    path: SUPPLIER_ADD,
    element: (
      <PrivateRoute>
        <Page component={AddAndUpdateVendor} />
      </PrivateRoute>
    ),
  },
  {
    path: SUPPLIER_EDIT,
    element: (
      <PrivateRoute>
        <Page component={AddAndUpdateVendor} />
      </PrivateRoute>
    ),
  },
  {
    path: CUSTOMER,
    element: (
      <PrivateRoute permission="customer-view">
        <Page component={Customer} />
      </PrivateRoute>
    ),
  },
  {
    path: CUSTOMER_ADD,
    element: (
      <PrivateRoute permission="customer-create">
        <Page component={AddAndUpdateCustomer} />
      </PrivateRoute>
    ),
  },
  {
    path: CUSTOMER_EDIT,
    element: (
      <PrivateRoute permission="customer-update">
        <Page component={AddAndUpdateCustomer} />
      </PrivateRoute>
    ),
  },
  {
    path: CASH_BOOK,
    element: (
      <PrivateRoute permission="cashBook-view">
        <Page component={CashAndCheque} />
      </PrivateRoute>
    ),
  },
  {
    path: CHEQUE_BOOK,
    element: (
      <PrivateRoute permission="chequeBook-view">
        <Page component={CashAndCheque} />
      </PrivateRoute>
    ),
  },
  {
    path: CASH_CREDIT_DEBIT,
    element: (
      <PrivateRoute>
        <Page component={CreditAndDebit} />
      </PrivateRoute>
    ),
  },
  {
    path: CHEQUE_CREDIT_DEBIT,
    element: (
      <PrivateRoute>
        <Page component={CreditAndDebit} />
      </PrivateRoute>
    ),
  },
  {
    path: INVOICE,
    element: (
      <PrivateRoute permission="invoice-view">
        <Page component={Invoice} />
      </PrivateRoute>
    ),
  },
  {
    path: INVOICE_ADD,
    element: (
      <PrivateRoute permission="invoice-create">
        <Page component={AddAndUpdateInvoice} />
      </PrivateRoute>
    ),
  },
  {
    path: INVOICE_EDIT,
    element: (
      <PrivateRoute permission="invoice-update">
        <Page component={AddAndUpdateInvoice} />
      </PrivateRoute>
    ),
  },
  {
    path: BILL,
    element: (
      <PrivateRoute permission="bill-view">
        <Page component={Bill} />
      </PrivateRoute>
    ),
  },
  {
    path: BILL_ADD,
    element: (
      <PrivateRoute permission="bill-create">
        <Page component={AddAndUpdateBill} />
      </PrivateRoute>
    ),
  },
  {
    path: BILL_EDIT,
    element: (
      <PrivateRoute permission="bill-update">
        <Page component={AddAndUpdateBill} />
      </PrivateRoute>
    ),
  },
  {
    path: EDIT_PROFILE,
    element: (
      <PrivateRoute>
        <Page component={EditProfile} />
      </PrivateRoute>
    ),
  },
  {
    path: ON_ACCOUNT,
    element: (
      <PrivateRoute>
        <Page component={OnAccount} />
      </PrivateRoute>
    ),
  },
  {
    path: ON_ACCOUNT_ADD,
    element: (
      <PrivateRoute>
        <Page component={AddOnAccount} />
      </PrivateRoute>
    ),
  },
  {
    path: EXPENSE_TYPE,
    element: (
      <PrivateRoute permission="expenseType-view">
        <Page component={ExpenseType} />
      </PrivateRoute>
    ),
  },
  {
    path: LEDGER,
    element: (
      <PrivateRoute permission="ledger-view">
        <Page component={Ledger} />
      </PrivateRoute>
    ),
  },
  {
    path: LOGS,
    element: (
      <PrivateRoute permission="log-view">
        <Page component={Logs} />
      </PrivateRoute>
    ),
  },
  {
    path: CASH_SELL,
    element: (
      <PrivateRoute permission="cashSell-view">
        <Page component={CashSell} />
      </PrivateRoute>
    ),
  },
  {
    path: CASH_SELL_ADD,
    element: (
      <PrivateRoute permission="cashSell-create">
        <Page component={AddAndUpdateCashSell} />
      </PrivateRoute>
    ),
  },
  {
    path: CASH_SELL_EDIT,
    element: (
      <PrivateRoute permission="cashSell-update">
        <Page component={AddAndUpdateCashSell} />
      </PrivateRoute>
    ),
  },
  {
    path: BANK_TRANSFER,
    element: (
      <PrivateRoute permission="bankTransaction-view">
        <Page component={BankTransfer} />
      </PrivateRoute>
    ),
  },
  {
    path: BANK_TRANSFER_EDIT,
    element: (
      <PrivateRoute permission="bankTransaction-update">
        <Page component={AddAndUpdateBankTransfer} />
      </PrivateRoute>
    ),
  },
  {
    path: BANK_TRANSFER_SETTLEMENT,
    element: (
      <PrivateRoute permission="bankTransaction-settlement">
        <Page component={Settlement} />
      </PrivateRoute>
    ),
  },
];

export default dashboardRoutes;
