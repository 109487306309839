import React, { useEffect, useRef, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import { Dashboard, ViewHeadline } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PageContainer from "@jumbo/components/PageContainer";
import "./AccountStatement.css"; // Importing CSS for exact styling

import { HOME } from "@jumbo/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { getPartiList } from "app/redux/slice/partiSlice";
import JumboAutoComplete from "@jumbo/components/JumboAutoComplete";
import { getLedgerList } from "app/redux/slice/entrybookSlice";
import NoDataPlaceholder from "app/shared/NoDataPlaceholder";
import { getCustomerList } from "app/redux/slice/customerSlice";
import JumboSelectBox from "@jumbo/components/JumboSelectBox";

const useStyles = makeStyles({
  buttonPadding: {
    width: "fit-content",
    marginTop: "20px",
    marginRight: "12px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "20px",
  },
  dropDownContainer: {
    padding: "20px 12px 0px 12px",
  },
  filterContainer: {
    marginBottom: "30px",
  },
});

const breadcrumbData = [
  {
    path: HOME,
    icon: <Dashboard />,
    label: "Dashboard",
  },
  {
    icon: <ViewHeadline />,
    label: "Ledger",
  },
];

const typeOfData = [
  { id: "supplier", label: "Vendor" },
  { id: "customer", label: "Customer" },
];

const validationSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(["customer", "supplier"])
    .required("Type is required"),
  supplier_id: yup.string().when("type", {
    is: "supplier",
    then: (schema) => schema.required("Please select vendor."),
    otherwise: (schema) => schema.notRequired(),
  }),
  customer_id: yup.string().when("type", {
    is: "customer",
    then: (schema) => schema.required("Please select customer."),
    otherwise: (schema) => schema.notRequired(),
  }),
  toDate: yup.date().when("fromDate", (fromDate, schema) => {
    if (fromDate) {
      return schema
        .required("To Date is required")
        .min(fromDate, "To Date cannot be before From Date");
    }
    return schema;
  }),
});

const Ledger = () => {
  const ledgerRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const supplierData = useSelector((state) => state?.supplier);
  const customerData = useSelector((state) => state?.customer);
  const [supplier, setSupplier] = useState();
  const [customer, setCustomer] = useState();
  const [view, setView] = useState();
  const [closingBalance, setClosingBalance] = useState([]);
  let totalCredit = 0;
  let totalDebit = 0;

  const [data, setData] = useState([]);

  const getData = async () => {
    const value = {
      from_date: values?.fromDate,
      to_date: values?.toDate,
      type: values?.type,
    };
    const response = await dispatch(
      getLedgerList({
        id: values?.type === "customer" ? customer?.id : supplier?.id,
        data: value,
      })
    ).unwrap();
    if (response.status === 200) {
      const tempObj = response.data?.data?.ledger;
      setClosingBalance(tempObj?.closing_obj);
      if (tempObj?.opening_obj) {
        setData([
          {
            date: tempObj?.opening_obj?.date,
            description: tempObj?.opening_obj?.description,
            amount: tempObj?.opening_obj?.amount,
            type: tempObj?.opening_obj?.type,
            balance:
              tempObj?.opening_obj?.type === "credit"
                ? -tempObj?.opening_obj?.amount
                : tempObj?.opening_obj?.amount,
            credit_amount:
              tempObj?.opening_obj?.type === "credit"
                ? tempObj?.opening_obj?.amount
                : null,
            debit_amount:
              tempObj?.opening_obj?.type !== "credit"
                ? tempObj?.opening_obj?.amount
                : null,
          },
          ...tempObj?.records,
        ]);
      } else {
        setData(tempObj?.records);
      }
      setView(true);
    } else {
      enqueueSnackbar(response.data?.response_message, {
        variant: "error",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      type: "supplier",
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: getData,
  });

  useEffect(() => {
    if (
      localStorage.getItem("store_id") &&
      localStorage.getItem("store_id") !== "0"
    ) {
      dispatch(getCustomerList({ store_id: localStorage.getItem("store_id") }));
      dispatch(getPartiList({ store_id: localStorage.getItem("store_id") }));
    } else {
      dispatch(getCustomerList());
      dispatch(getPartiList());
    }
  }, [dispatch]);

  const downloadPdf = () => {
    const input = ledgerRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("ledger.pdf");
    });
  };
  const { setFieldValue, errors, values, handleSubmit } = formik;

  return (
    <PageContainer heading="Ledger" breadcrumb={breadcrumbData}>
      <Paper
        className={
          (supplier?.id || customer?.id) && view && data.length !== 0
            ? ""
            : classes?.filterContainer
        }
      >
        <Card sx={{ display: "flex" }}>
          <Div sx={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <JumboSelectBox
                        data={typeOfData}
                        id="id"
                        name={"type"}
                        size="small"
                        labelKey="label"
                        valueKey="id"
                        label="Type*"
                        required
                        value={values?.type}
                        onChange={(evt) => {
                          setFieldValue("type", evt.target.value);
                          setView(false);
                        }}
                      />
                    </Grid>
                    {values?.type === "supplier" && (
                      <Grid item xs={12} sm={3}>
                        <JumboAutoComplete
                          options={supplierData.list ?? []}
                          label="Vendor*"
                          size="small"
                          getOptionLabel={(option) => option.name}
                          name="supplier_id"
                          onChange={(evt, value) => {
                            setSupplier(value);
                            if (!value) setView(false);
                            setFieldValue("supplier_id", value?.id);
                          }}
                          error={errors?.supplier_id}
                          helperText={errors?.supplier_id}
                        />
                      </Grid>
                    )}
                    {values?.type === "customer" && (
                      <Grid item xs={12} sm={3}>
                        <JumboAutoComplete
                          options={customerData.list ?? []}
                          label="Customer*"
                          size="small"
                          getOptionLabel={(option) => option.name}
                          name="customer_id"
                          onChange={(evt, value) => {
                            setCustomer(value);
                            if (!value) setView(false);
                            setFieldValue("customer_id", value?.id);
                          }}
                          error={errors?.customer_id}
                          helperText={errors?.customer_id}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"From Date"}
                        name="fromDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.fromDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <JumboTextField
                        type="date"
                        label={"To Date"}
                        name="toDate"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                        value={values?.toDate}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardContent>
          </Div>
        </Card>
      </Paper>
      {(supplier?.id || customer?.id) && view && data.length !== 0 ? (
        <Button onClick={downloadPdf}>Download PDF</Button>
      ) : (
        <></>
      )}

      <Paper>
        {view &&
          (data.length !== 0 ? (
            <div ref={ledgerRef} id="ledger-section">
              <div className="account-statement-container">
                <header className="statement-header">
                  <h1>OM PARTICLE</h1>
                  <p>
                    1st Floor Prabhu Prasad Near New SRK House Kasanagar Cir.
                    opp. Hindu Milan Mandir
                  </p>
                  <p>
                    Baxi Faliyu, Katargam, Surat Gujarat 395004, MO.9081446111
                  </p>
                </header>
                <h3>
                  Account Statement For{" "}
                  {values?.type === "customer"
                    ? customer?.name
                    : supplier?.name}
                </h3>
                {values.fromDate && values.toDate && (
                  <p>
                    {`From ${moment(values.fromDate, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )} To ${moment(values.toDate, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )}`}
                  </p>
                )}
                <table className="statement-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Closing Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => {
                      totalCredit += row?.credit_amount ?? 0;
                      totalDebit += row?.debit_amount ?? 0;
                      return (
                        <tr key={index}>
                          <td>{moment(row?.date).format("DD/MM/YYYY")}</td>
                          <td>{row?.description ?? ""}</td>
                          <td>{row?.credit_amount ?? ""}</td>
                          <td>{row?.debit_amount ?? ""}</td>
                          <td>
                            {row?.balance > 0
                              ? `${row?.balance} DB`
                              : `${Math.abs(row?.balance)} CR`}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td className="total">Total</td>
                      <td className="total">{totalCredit}</td>
                      <td className="total">{totalDebit}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p className="closing-balance">
                  Closing Balance :{" "}
                  {`${
                    closingBalance?.type === "debit"
                      ? `${closingBalance?.amount} CR`
                      : `${closingBalance?.amount} DB`
                  }`}
                </p>
              </div>
            </div>
          ) : (
            <NoDataPlaceholder />
          ))}
      </Paper>
    </PageContainer>
  );
};

export default Ledger;
