import React from "react";
import {
  // People,
  // Person,
  // RequestQuote,
  // ManageAccounts,
  // Paid,
  // Lock,
  // Dashboard,
  // LocalAtm,
  // Receipt,
  // PersonAddAlt,
  // CurrencyExchange,
  // ReceiptLong,
  // AccountBalanceWallet,
  // Storage,
  // ViewHeadline,
  Home,
  Diamond,
  AcUnit,
  AccountBalance,
  SsidChart,
  Inventory,
  AssignmentInd,
  Settings,
} from "@mui/icons-material";

import {
  INVOICE,
  CASH_BOOK,
  CHEQUE_BOOK,
  BILL,
  EXPENSE_TYPE,
  LOGS,
  CUSTOMER,
  LEDGER,
  CASH_SELL,
  BANK_TRANSFER,
} from "@jumbo/constants/routes";
export const url = process.env.REACT_APP_OM_URL;

const menus = [
  {
    label: " ",
    type: "section",
    children: [
      {
        uri: `${url}dashboard`,
        label: "Dashboard",
        type: "nav-item",
        icon: <Home sx={{ fontSize: 20 }} />,
      },
      {
        label: "Diamonds",
        type: "collapsible",
        icon: <Diamond sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: `${url}products`,
            label: "All Diamonds",
            type: "nav-item",
            permission: "product-view",
          },
          {
            uri: `${url}products/available/product`,
            label: "Available Diamonds",
            type: "nav-item",
            permission: "availableProduct-view",
          },
          {
            uri: `${url}products/options`,
            label: "Attributes",
            type: "nav-item",
            permission: "product-view",
          },
          {
            uri: `${url}vendors`,
            label: "Vendor",
            type: "nav-item",
            permission: "vendor-view",
          },
        ],
      },
      {
        label: "Jewellery",
        type: "collapsible",
        icon: <AcUnit sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: `${url}jewellery`,
            label: "All Jewellery",
            type: "nav-item",
            permission: "jewellery-view",
          },
          {
            uri: `${url}jewellery/available/jewellery`,
            label: "Available Jewellery",
            type: "nav-item",
            permission: "availableJewellery-view",
          },
        ],
      },
      {
        uri: `${url}commodity`,
        label: "Commodity",
        type: "nav-item",
        icon: <SsidChart sx={{ fontSize: 20 }} />,
      },
      {
        label: "Inventory",
        type: "collapsible",
        icon: <Inventory sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: `${url}inventory`,
            label: "Master",
            type: "nav-item",
            permission: "master-view",
          },
          {
            uri: `${url}inventory/create`,
            label: "Internal Transfer",
            type: "nav-item",
            permission: "internalTransfer-view",
          },
          {
            uri: `${url}inventory/on-memo/latest`,
            label: "On Memo",
            type: "nav-item",
            permission: "onMemo-view",
          },
        ],
      },
      {
        label: "Reports",
        type: "collapsible",
        icon: <AssignmentInd sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: `${url}reports/vendors`,
            label: "Vendor Report",
            type: "nav-item",
          },
          {
            uri: `${url}reports/stores`,
            label: "Store Report",
            type: "nav-item",
          },
        ],
      },
      {
        label: "Settings",
        type: "collapsible",
        icon: <Settings sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: `${url}settings/index`,
            label: "General",
            type: "nav-item",
            permission: "general-view",
          },
          {
            uri: `${url}currencies`,
            label: "Currency",
            type: "nav-item",
            permission: "currency-view",
          },
          {
            uri: `${url}stores`,
            label: "Store",
            type: "nav-item",
            permission: "store-view",
          },
        ],
      },
      {
        label: "Accounts",
        type: "collapsible",
        icon: <AccountBalance sx={{ fontSize: 20 }} />,
        children: [
          // {
          //   uri: SUPPLIER,
          //   label: "Supplier",
          //   type: "nav-item",
          //   // icon: <PersonAddAlt sx={{ fontSize: 20 }} />,
          // },
          {
            uri: CUSTOMER,
            label: "Customer",
            type: "nav-item",
            permission: "customer-view",
            // icon: <People sx={{ fontSize: 20 }} />,
          },
          {
            uri: BILL,
            label: "Bill (Purchase)",
            type: "nav-item",
            permission: "bill-view",
            // icon: <ReceiptLong sx={{ fontSize: 20 }} />,
          },
          {
            uri: INVOICE,
            label: "Invoice (Sell)",
            type: "nav-item",
            permission: "invoice-view",
            // icon: <Receipt sx={{ fontSize: 20 }} />,
          },
          {
            uri: CASH_SELL,
            label: "Cash Sell",
            type: "nav-item",
            permission: "cashSell-view",
          },
          {
            uri: CASH_BOOK,
            label: "Cash Book",
            type: "nav-item",
            // icon: <Paid sx={{ fontSize: 20 }} />,
          },
          {
            uri: CHEQUE_BOOK,
            label: "Cheque Book",
            type: "nav-item",
            // icon: <LocalAtm sx={{ fontSize: 20 }} />,
          },
          {
            uri: EXPENSE_TYPE,
            label: "Expense Type",
            type: "nav-item",
            permission: "expenseType-view",
            // icon: <CurrencyExchange sx={{ fontSize: 20 }} />,
          },
          {
            uri: BANK_TRANSFER,
            label: "Bank Transaction",
            type: "nav-item",
            permission: "bankTransaction-view",
          },
          {
            uri: LEDGER,
            label: "Ledger",
            type: "nav-item",
            permission: "ledger-view",
            // icon: <ViewHeadline sx={{ fontSize: 20 }} />,
          },
          {
            uri: LOGS,
            label: "Logs",
            type: "nav-item",
            permission: "log-view",
            // icon: <Storage sx={{ fontSize: 20 }} />,
          },
        ],
      },
    ],
  },
];

export default menus;
