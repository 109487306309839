import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getCashSellList = createAsyncThunk(
  "get-cash-sell",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("cash-sell/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createCashSell = createAsyncThunk(
  "create-cash-sell",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("cash-sell", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createCashSellToInvoice = createAsyncThunk(
  "create-cash-sell",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("cash-sell/invoice", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editCashSell = createAsyncThunk(
  "edit-cash-sell",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`cash-sell/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateCashSell = createAsyncThunk(
  "update-cash-sell",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/cash-sell/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteCashSell = createAsyncThunk(
  "delete-cash-sell",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/cash-sell/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteCashSellItem = createAsyncThunk(
  "delete-cash-sell-item",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/cash-sell/item/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const downloadCashSell = createAsyncThunk(
  "download-cash-sell",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`cash-sell/download/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const cashSellSlice = createSlice({
  name: "cashSell",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCashSellList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCashSellList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.cash_sell_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getCashSellList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default cashSellSlice.reducer;
