import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
  bookBalance: 0,
  currentBalance: 0,
};

export const getEntryBookList = createAsyncThunk(
  "get-entry-book",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("entry-book/list", { params: data });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const getLedgerList = createAsyncThunk(
  "get-ledger",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.get(`entry-book/ledger/${id}`, {
        params: data,
      });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const createEntryBook = createAsyncThunk(
  "create-entry-book",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("entry-book", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteEntryBook = createAsyncThunk(
  "delete-entry-book",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/entry-book/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const fundTransferEntryBook = createAsyncThunk(
  "fund-transfer-entry-book",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("entry-book/fund-transfer", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const entrybookSlice = createSlice({
  name: "entryBook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEntryBookList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntryBookList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.entry_book_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
        state.bookBalance = action?.payload?.data?.data?.book_balance ?? 0;
        state.currentBalance =
          action?.payload?.data?.data?.current_balance ?? 0;
      })
      .addCase(getEntryBookList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default entrybookSlice.reducer;
