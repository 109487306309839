import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchError } from "./commonSlice";

const initialState = {
  list: [],
  perPage: "",
  total: 0,
  loading: false,
};

export const getBankTransactionList = createAsyncThunk(
  "get-bank-transaction",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get("bank-transaction/list", {
        params: data,
      });
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const importBankTransaction = createAsyncThunk(
  "import-bank-transaction",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("bank-transaction/import", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const editBankTransaction = createAsyncThunk(
  "edit-bank-transaction",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(`bank-transaction/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const updateBankTransaction = createAsyncThunk(
  "update-bank-transaction",
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await axios.put(`/bank-transaction/${id}`, data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const deleteBankTransaction = createAsyncThunk(
  "delete-bank-transaction",
  async (id, { dispatch }) => {
    try {
      const response = await axios.delete(`/bank-transaction/${id}`);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

export const settlementBankTransaction = createAsyncThunk(
  "settlement-bank-transaction",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("bank-transaction/settlement", data);
      return response;
    } catch (error) {
      dispatch(fetchError(error));
      return error;
    }
  }
);

const bankTransactionSlice = createSlice({
  name: "bankTransaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankTransactionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBankTransactionList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data?.data?.transaction_list;
        state.perPage = action?.payload?.data?.data?.per_page;
        state.total = action?.payload?.data?.data?.total;
      })
      .addCase(getBankTransactionList.rejected, (state, action) => {
        state.loading = false;
        state.list = "";
        state.perPage = "";
        state.total = "";
      });
  },
});

export default bankTransactionSlice.reducer;
