export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/set-password';
export const HOME = '/';
export const CHANGE_PASSWORD = '/change-password';
export const EDIT_PROFILE = '/edit-profile'

export const USER = "/dashboards/users";
export const USER_ADD = "/dashboards/add-users";
export const USER_EDIT = "/dashboards/update-users/:id";
export const createUserEditRoute = (id) => `/dashboards/update-users/${id}`;

export const CLIENT = "/client";
export const CLIENT_ADD = "/add-client";
export const CLIENT_EDIT = "/edit-client/:id";
export const createClientEditRoute = (id) => `/edit-client/${id}`;

export const PORTFOLIO = '/dashboards/portfolio';
export const PORTFOLIO_VIEW = '/dashboards/view-portfolio/:id';
export const createPortfolioViewRoute = id => `/dashboards/view-portfolio/${id}`;

export const TAX = '/tax';

export const SERVICE = '/service';

export const CASH_BOOK = "/cash-book";
export const CHEQUE_BOOK = "/cheque-book";
export const createCashChequeCreditDebitRoute = (id, type) =>`${id}?type=${type}`;
export const CASH_CREDIT_DEBIT = "/cash";
export const CASH ="/cash"
export const CHEQUE_CREDIT_DEBIT = "/cheque";
export const CHEQUE="/cheque"

export const REPORT = '/report';

export const INVOICE = '/invoice';
export const INVOICE_ADD = '/add-invoice'
export const INVOICE_EDIT = '/edit-invoice/:id';
export const createInvoiceEditRoute = id => `/edit-invoice/${id}`;
export const addInvoiceClientIdRoute = (id) => `/add-invoice?client-id=${id}`;

export const ROLE = '/dashboards/role';
export const ROLE_PERMISSION = '/dashboards/permission-role/:id';
export const createPermissionRoute = (id) => `/dashboards/permission-role/${id}`;

export const SUPPLIER = '/supplier';
export const SUPPLIER_ADD = '/add-supplier';
export const SUPPLIER_EDIT = '/edit-supplier/:id';
export const createSupplierEditRoute = id => `/edit-supplier/${id}`;

export const CUSTOMER = '/customer';
export const CUSTOMER_ADD = '/add-customer';
export const CUSTOMER_EDIT = '/edit-customer/:id';
export const createCustomerEditRoute = id => `/edit-customer/${id}`;

export const ON_ACCOUNT = '/on-account';
export const ON_ACCOUNT_ADD = '/add-on-account';

export const EXPENSE = '/expense';
export const EXPENSE_ADD = '/add-expense';
export const EXPENSE_EDIT = '/edit-expense/:id';
export const createExpenseEditRoute = id => `/edit-expense/${id}`

export const EXPENSE_TYPE = '/expense-type';

export const LOGS = '/logs';

export const LEDGER = '/ledger';

export const BILL = '/bill';
export const BILL_ADD = '/add-bill'

export const BILL_EDIT = '/edit-bill/:id';
export const createBillEditRoute = id => `/edit-bill/${id}`;
export const addBillClientIdRoute = (id) => `/add-bill?vendor-id=${id}`;

export const CASH_SELL = '/cash-sell';
export const CASH_SELL_ADD = '/add-cash-sell'
export const CASH_SELL_EDIT = '/edit-cash-sell/:id';
export const createCashSellEditRoute = id => `/edit-cash-sell/${id}`;
export const addCashSellClientIdRoute = (id) => `/add-cash-sell?client-id=${id}`;

export const BANK_TRANSFER = '/bank-transaction';
export const BANK_TRANSFER_EDIT = '/edit-bank-transaction/:id';
export const createBankTransferEditRoute = id => `/edit-bank-transaction/${id}`
export const BANK_TRANSFER_SETTLEMENT = '/settlement-bank-transaction/:id';
export const createBankTransferSettlementRoute = id => `/settlement-bank-transaction/${id}`